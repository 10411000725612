import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import { createSearchParams } from 'react-router-dom';
import config from './config';
import { MessageType, TaksType } from './helpers/types';
import {
  DscExpiryDateOptionItem,
  DscPasswordOptionItem,
} from './pages/ContactPerson/DSC';
import { BooleanString } from './pages/RegisterInOut';
import {
  RegisterMode,
  RegisterType,
} from './pages/RegisterInOut/AddEditRegisterInOut';
import { ChangeType, LogType } from './pages/Reports/ReportFilters';
import { NotificationSettingsType } from './pages/Settings/Notifications/Index.tsx';
import { PurchaseType } from './pages/Subscription';
import { nonAuthRoutes } from './constants/routes';

axios.defaults.baseURL = config.baseURL;
axios.defaults.withCredentials = true;

// Add this before the interceptor
let isRefreshing = false;
let refreshSubscribers: ((token?: string) => void)[] = [];

// Subscribe failed requests to the token refresh
function subscribeTokenRefresh(cb: (token?: string) => void) {
  refreshSubscribers.push(cb);
}

// Retry failed requests with new token
function onRefreshed() {
  refreshSubscribers.forEach((cb) => cb());
  refreshSubscribers = [];
}

axios.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    // if token is expired but refresh token is valid
    if (
      error.response?.status === 401 &&
      error.response?.data?.tokenExpired &&
      !error.response?.data?.refreshTokenExpired &&
      !error.response?.data?.refreshTokenInvalid &&
      !originalRequest._retry
    ) {
      if (isRefreshing) {
        // Wait for the other refresh request
        return new Promise((resolve) => {
          subscribeTokenRefresh(() => {
            resolve(axios(originalRequest));
          });
        });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        await axios.post('/auth/refreshToken');
        isRefreshing = false;
        onRefreshed();
        return axios(originalRequest);
      } catch (error) {
        isRefreshing = false;
        refreshSubscribers = [];
        window.location.href = '/signin';
        return Promise.reject(error);
      }
    }

    // if token not found error came from backend or refresh token expired then logout
    if (
      (error.response?.data?.tokenFound === false ||
        error.response?.data?.refreshTokenExpired ||
        error.response?.data?.refreshTokenInvalid ||
        error.response?.data?.tokenInvalid) &&
      !nonAuthRoutes.includes(window.location.pathname)
    ) {
      // Force logout

      Auth.logout()
        .then(() => {
          // Redirect to login page
          window.location.href = '/signin?forceLogout=true';
        })
        .catch(() => {
          // Even if logout API fails, redirect to login

          window.location.href = '/signin?forceLogout=true';
        });
    }
    return Promise.reject(error);
  },
);

const responseBody = (res: AxiosResponse) => res.data;

const requests = {
  get: (url: string) => axios.get(url).then(responseBody),
  getBlob: (url: string, options: AxiosRequestConfig) =>
    axios.get(url, { ...options }),
  post: (url: string, body?: any) => axios.post(url, body).then(responseBody),
  put: (url: string, body: any) => axios.put(url, body).then(responseBody),
  patch: (url: string, body: any) => axios.patch(url, body).then(responseBody),
  delete: (url: string, body?: any) =>
    axios.delete(url, { data: body }).then(responseBody),
};

const Ticket = {
  createTicket: (
    workSpaceId: string,
    workspaceName: string,
    userId: string,
    name: string,
    email: string,
    query: string,
    relatedTo: string,
    status: string,
    priority: string,
    attachments: any,
  ) => {
    const formData = new FormData();

    if (attachments.length > 0) {
      attachments.forEach((file, index) => {
        formData.append(`attachment${index}`, file);
      });
    }

    return requests.post(
      `/ticket/createTicket?workSpaceId=${workSpaceId}&workspaceName=${workspaceName}&userId=${userId}&name=${name}&email=${email}&query=${query}&relatedTo=${relatedTo}&status=${status}&priority=${priority}`,
      formData,
    );
  },
  ticketHistory: (
    workSpaceId: string,
    userId: string,
    limit: number,
    skip: number,
  ) =>
    requests.get(
      `/ticket/ticketHistory?workSpaceId=${workSpaceId}&userId=${userId}&limit=${limit}&skip=${skip}`,
    ),
  ticketDetail: (ticketId: string) =>
    requests.get(`/ticket/ticketDetail?ticketId=${ticketId}`),

  replyTicket: (
    ticketId: string,
    author: string,
    email: string,
    reply: string,
  ) =>
    requests.post(
      `/ticket/replyTicket?ticketId=${ticketId}&author=${author}&email=${email}&reply=${reply}`,
    ),
  changeStatus: (ticketId: string, status: string) =>
    requests.post(`/ticket/changeStatus?ticketId=${ticketId}&status=${status}`),
};

const Agency = {
  Client: {
    list: () => requests.get('/gsts'),
  },
};

const Auth = {
  //Login
  login: (username: string, password: string, token: string, version: string) =>
    requests.post('/auth/login', { username, password, token, version }),
  //Signup
  signup: (
    name: string,
    email: string,
    password: string,
    mobileNumber: string,
    token: string,
    version: string,
  ) =>
    requests.post('/auth/signup', {
      name,
      email,
      password,
      mobileNumber,
      token,
      version
    }),
  verifySignupOTP: (userId: string, otp: number) =>
    requests.post('/auth/verify/signup/otp', { userId, otp }),
  verifySignupToken: (token: string) =>
    requests.post('/auth/verify/signup/token', { token }),
  //Forgot Password
  forgotPassword: (email: string, token: string, version: string) =>
    requests.post('/auth/forgotPassword', { email, token, version }),
  verifyResetOtp: (userId: string, otp: number, password: string) =>
    requests.post('/auth/verify/reset/otp', { userId, otp, password }),
  verifyResetPassword: (currentPassword: string, newPassword: string) =>
    requests.post('/auth/verify/reset/password', {
      currentPassword,
      newPassword,
    }),
  setPassword: (setPassword: string) =>
    requests.post('/auth/verify/set/password', {
      setPassword,
    }),
  verifyResetToken: (token: string, password: string) =>
    requests.post('/auth/verify/reset/token', { token, password }),
  sendMobileVerificationOTP: (mobileNumber: string) =>
    requests.post('/auth/mobileVerification', { mobileNumber }),
  verifyMobileOTP: (mobileNumber: string, otp: string) =>
    requests.post('/auth/verify/mobile/otp', { mobile: mobileNumber, otp }),
  AddMobileNumberOtp: (mobileNumber: string) =>
    requests.post('/auth/AddMobileNumberOtp', { mobileNumber }),
  verifyAddMobileNumberOTP: (mobileNumber: string, otp: string) =>
    requests.post('/auth/verify/AddMobileNumber/otp', {
      mobile: mobileNumber,
      otp,
    }),
  checkToken: () => requests.get('/auth/checkToken'),
  logout: () => requests.post('/auth/logout'),
};

const Firm = {
  getFirms: () => requests.get('/workspace/list'),
  addFirm: (name: string) => requests.post('/workspace/add', { name }),
  editFirm: (workSpaceId: string, name: string) =>
    requests.patch('/workspace/updatename', { workSpaceId, name }),
  makeFirmInactive: (workSpaceId: string) =>
    requests.patch('/workspace/inactive', { workSpaceId }),
  makeFirmActive: (workSpaceId: string) =>
    requests.patch('/workspace/active', { workSpaceId }),
  listofInvitationSent: (workSpaceId: string) =>
    requests.get(`/workspace/listofinvitationsent?workSpaceId=${workSpaceId}`),
  listofInvitationReceived: () =>
    requests.get(`/workspace/listofinvitationreceived`),
  leaveFirm: (workSpaceId: string) =>
    requests.post('/workspace/leaveworkspace', { workSpaceId }),
  revokeInvitation: (workSpaceId: string, invitationId: string) =>
    requests.post('/workspace/revokeinvitation', { workSpaceId, invitationId }),
  rejectInvitation: (workSpaceId: string, invitationId: string) =>
    requests.post('/workspace/rejecttinvitation', {
      workSpaceId,
      invitationId,
    }),
  acceptInvitation: (workSpaceId: string, invitationId: string) =>
    requests.post('/workspace/acceptinvitation', { workSpaceId, invitationId }),
  addUser: (workSpaceId: string, userEmail: string) =>
    requests.post('/workspace/adduser', { workSpaceId, userEmail }),
  deleteFirm: (workSpaceId: string) =>
    requests.delete(`/workspace/delete?workSpaceId=${workSpaceId}`, {}),
  getFeesTrackingStatus: (workSpaceId: string) =>
    requests.get(`/workspace/getFeesTracking?workSpaceId=${workSpaceId}`),
  saveFeesTrackingStatus: (
    workSpaceId: string,
    trackingFees: boolean,
    notBillableTasks: object[],
  ) =>
    requests.post('/workspace/saveFeesTracking', {
      workSpaceId,
      trackingFees,
      notBillableTasks,
    }),
};

const Tag = {
  getTagList: (
    workSpaceId: string,
    active: boolean,
    searchText: string,
    limit: number,
    skip: number,
  ) =>
    requests.get(
      `/tag/list?workSpaceId=${workSpaceId}&active=${active}&skip=${skip}&limit=${limit}&searchText=${encodeURIComponent(
        searchText,
      )}`,
    ),
  addTag: (name: string, description: string, workSpaceId: string) =>
    requests.post('/tag/add', { name, description, workSpaceId }),
  editTag: (
    name: string,
    description: string,
    tagId: string,
    workSpaceId: string,
  ) => requests.put('/tag/edittag', { name, description, tagId, workSpaceId }),
  makeTagInactive: (id: string, workSpaceId: string) =>
    requests.put('/tag/inactive', { id, workSpaceId }),
  makeTagActive: (id: string, workSpaceId: string) =>
    requests.put('/tag/active', { id, workSpaceId }),
  deleteTag: (id: string, workSpaceId: string) =>
    requests.delete('/tag/delete', { id, workSpaceId }),
  getOneTag: (workSpaceId: string, tagId: string) =>
    requests.get(`/tag/id?tagId=${tagId}&workSpaceId=${workSpaceId}`),
};

const Status = {
  getStatusList: (
    workSpaceId: string,
    active: boolean,
    searchText: string,
    limit: number,
    skip: number,
  ) =>
    requests.get(
      `/status/list?workSpaceId=${workSpaceId}&active=${active}&skip=${skip}&limit=${limit}&searchText=${encodeURIComponent(
        searchText,
      )}`,
    ),
  addStatus: (
    name: string,
    color: string,
    description: string,
    workSpaceId: string,
    tasks: string[],
  ) =>
    requests.post('/status/add', {
      name,
      color,
      description,
      workSpaceId,
      tasks,
    }),
  editStatus: (
    statusId: string,
    name: string,
    color: string,
    description: string,
    workSpaceId: string,
    tasks: string[],
  ) =>
    requests.put('/status/edit', {
      statusId,
      name,
      color,
      description,
      workSpaceId,
      tasks,
    }),
  makeStatusInactive: (id: string, workSpaceId: string) =>
    requests.put('/status/inactive', { id, workSpaceId }),
  makeStatusActive: (id: string, workSpaceId: string) =>
    requests.put('/status/active', { id, workSpaceId }),
  statusTaskList: (workSpaceId: string) =>
    requests.get(`/status/listoftaskforstatus?workSpaceId=${workSpaceId}`),
  deleteStatus: (id: string, workSpaceId: string) =>
    requests.delete('/status/delete', { id, workSpaceId }),
  getOneStatus: (workSpaceId: string, statusId: string) =>
    requests.get(`/status/id?statusId=${statusId}&workSpaceId=${workSpaceId}`),
};

const User = {
  addFirebaseToken: (
    token: string,
    type: string,
    browser: string,
    device: string,
  ) => requests.post('/user/addpushtoken', { token, type, browser, device }),
  getSelfDetails: (workSpaceId: string) =>
    requests.get(`/user/getselfdetailsforworkspace?workSpaceId=${workSpaceId}`),
  getHashStatus: (workSpaceId: string) =>
    requests.get(`/user/getUserHashStatus?workSpaceId=${workSpaceId}`),

  getUserList: (
    workSpaceId: string,
    active: boolean,
    searchText: string,
    includeCurrent: boolean,
  ) =>
    requests.get(
      `/user/list?workSpaceId=${workSpaceId}&active=${active}&searchText=${encodeURIComponent(
        searchText,
      )}&includeCurrent=${includeCurrent}`,
    ),
  addUser: (
    workSpaceId: string,
    email: string,
    role: string,
    userRightsList: any,
  ) =>
    requests.post('workspace/adduser', {
      workSpaceId,
      email,
      role,
      userRightsList,
    }),

  editUser: (
    workSpaceId: string,
    email: string,
    role: string,
    userRightsList: any,
  ) =>
    requests.post('workspace/edituser', {
      workSpaceId,
      email,
      role,
      userRightsList,
    }),
  updateUserName: (name: string) =>
    requests.patch('/user/changename', { name }),
  updateUserShortName: (shortName: string) =>
    requests.patch('/user/changeshortname', { shortName }),
  getUserRights: (workSpaceId: string, userId?: string) =>
    requests.get(
      `/user/rights?workSpaceId=${workSpaceId}${
        userId ? `&userId=${userId}` : ''
      }`,
    ),
  makeUserInactive: (workSpaceId: string, userId: string) =>
    requests.patch('/user/inactive', { workSpaceId, userId }),
  makeUserActive: (workSpaceId: string, userId: string) =>
    requests.patch('/user/active', { workSpaceId, userId }),
  removeUser: (workSpaceId: string, userId: string) =>
    requests.post('/workspace/removeuser', { workSpaceId, userId }),
  addClickId: (
    gclid: string,
    fbclid: string,
    twclid: string,
    li_fat_id: string,
  ) => requests.put('/user/addClickId', { gclid, fbclid, twclid, li_fat_id }),
  saveIP: (ip: string) => requests.put('/user/saveIP', { ip }),
};

const ContactPerson = {
  getPersonList: (
    workSpaceId: string,
    active: boolean,
    searchText: string,
    limit: number,
    skip: number,
    download?: boolean,
  ) => {
    const query = `/contactperson/list?workSpaceId=${workSpaceId}&active=${active}&skip=${skip}&limit=${limit}&searchText=${encodeURIComponent(
      searchText,
    )}`;

    return download
      ? requests.getBlob(`${query}&download=${download}`, {
          responseType: 'blob',
        })
      : requests.get(query);
  },

  getDscList: (
    workSpaceId: string,
    active: boolean,
    skip: number,
    limit: number,
    searchText: string,
    expiryDate: DscExpiryDateOptionItem,
    password: DscPasswordOptionItem,
    download?: boolean,
  ) => {
    return download
      ? requests.getBlob(
          `/contactperson/dsclist?workSpaceId=${workSpaceId}&active=${active}&skip=${skip}&limit=${limit}&searchText=${encodeURIComponent(
            searchText,
          )}&expiryDate=${expiryDate}&password=${password}&download=${download}`,
          {
            responseType: 'blob',
          },
        )
      : requests.get(
          `/contactperson/dsclist?workSpaceId=${workSpaceId}&active=${active}&skip=${skip}&limit=${limit}&searchText=${encodeURIComponent(
            searchText,
          )}&expiryDate=${expiryDate}&password=${password}&download=${download}`,
        );
  },
  addPerson: (
    workSpaceId: string,
    name: string,
    email: any,
    mobile: any,
    dscPassword: string,
    addressLine1: string,
    addressLine2: string,
    pincode: string,
    city: string,
    state: string,
    customFields: any,
    dscExpiryDate: string,
    dateOfBirth: string,
    dateOfAnniversary: string,
    pan: string,
    din: string,
    allowDuplicates: boolean,
  ) =>
    requests.post('/contactperson/add', {
      workSpaceId,
      name,
      email,
      mobile,
      dscPassword,
      addressLine1,
      addressLine2,
      pincode,
      city,
      state,
      customFields,
      dscExpiryDate,
      dateOfBirth,
      dateOfAnniversary,
      pan,
      din,
      allowDuplicates,
    }),
  editPerson: (
    contactPersonId: string,
    workSpaceId: string,
    name: string,
    email: any,
    mobile: any,
    dscPassword: string,
    addressLine1: string,
    addressLine2: string,
    pincode: string,
    city: string,
    state: string,
    customFields: any,
    dscExpiryDate: string,
    dateOfBirth: string,
    dateOfAnniversary: string,
    pan: string,
    din: string,
    allowDuplicates: boolean,
    bulkEdit?: boolean,
    bulkEditField?: 'name' | 'email' | 'mobile' | '',
  ) =>
    requests.put('/contactperson/edit', {
      contactPersonId,
      workSpaceId,
      name,
      email,
      mobile,
      dscPassword,
      addressLine1,
      addressLine2,
      pincode,
      city,
      state,
      customFields,
      dscExpiryDate,
      dateOfBirth,
      dateOfAnniversary,
      pan,
      din,
      allowDuplicates,
      bulkEdit,
      bulkEditField,
    }),
  getPersonById: (workSpaceId: string, contactPersonId: string) =>
    requests.get(
      `/contactperson/id?contactPersonId=${contactPersonId}&workSpaceId=${workSpaceId}`,
    ),
  getPersonByIds: (workSpaceId: string, contactPersonIds: string[]) =>
    requests.get(
      `/contactperson/ids?&${
        contactPersonIds.length > 0
          ? createSearchParams({ 'contactPersonIds[]': contactPersonIds })
          : ''
      }&workSpaceId=${workSpaceId}`,
    ),

  makePersonActive: (contactPersonId: string, workSpaceId: string) =>
    requests.put('/contactperson/active', { contactPersonId, workSpaceId }),
  makePersonInactive: (contactPersonId: string, workSpaceId: string) =>
    requests.put('/contactperson/inactive', { contactPersonId, workSpaceId }),
  deletePersonField: (contactPersonId: string, workSpaceId: string) =>
    requests.delete(
      `/contactperson/delete?contactPersonId=${contactPersonId}&workSpaceId=${workSpaceId}`,
      {},
    ),
  getPinCodeData: (pincode: string) => requests.get(`/pincode/${pincode}`),

  getBulkUploadSheet: (workSpaceId: string) =>
    requests.getBlob(
      `/bulkupload/contactPerson/sheet?workSpaceId=${workSpaceId}`,
      { responseType: 'blob' },
    ),
  validateUpload: (body: any) =>
    requests.post(`/bulkupload/contactPerson/validateSheet`, body),
  updateRowField: (
    workSpaceId: string,
    requestId: string,
    entryId: string,
    columnToUpdate: string,
    value: string,
  ) =>
    requests.post(`/bulkupload/contactPerson/fieldUpdate`, {
      workSpaceId,
      requestId,
      entryId,
      columnToUpdate,
      value,
    }),
  getColumnErrors: (workSpaceId: string, requestId: string, column: string) =>
    requests.get(
      `/bulkupload/contactPerson/columnError?workSpaceId=${workSpaceId}&requestId=${requestId}&column=${column}`,
    ),
  getErrorReport: (workSpaceId: string, requestId: string) =>
    requests.getBlob(
      `/bulkupload/contactPerson/errorReport?workSpaceId=${workSpaceId}&requestId=${requestId}`,
      { responseType: 'blob' },
    ),
  getAllBulkUploadRequests: (workSpaceId: string) =>
    requests.get(
      `/bulkupload/contactPerson/requests?workSpaceId=${workSpaceId}`,
    ),
  getOnebulkUploadRequest: (workSpaceId: string, requestId: string) =>
    requests.get(
      `/bulkupload/contactPerson/request?workSpaceId=${workSpaceId}&requestId=${requestId}`,
    ),
  getErrorColumns: (workSpaceId: string, requestId: string) =>
    requests.get(
      `/bulkupload/contactPerson/errColumns?workSpaceId=${workSpaceId}&requestId=${requestId}`,
    ),
  startImport: (workSpaceId: string, requestId: string) =>
    requests.post(`/bulkupload/contactPerson/changeReqStatus`, {
      workSpaceId,
      requestId,
    }),
};

const CustomField = {
  getCustomFieldList: (
    workSpaceId: string,
    active: boolean,
    searchText: string,
    limit: number,
    skip: number,
  ) =>
    requests.get(
      `customfields/list?workSpaceId=${workSpaceId}&active=${active}&limit=${limit}&skip=${skip}&searchText=${encodeURIComponent(
        searchText,
      )}`,
    ),
  addCustomField: (
    name: string,
    description: string,
    type: string,
    applicableFor: string,
    workSpaceId: string,
  ) =>
    requests.post('/customfields/add', {
      name,
      description,
      type,
      applicableFor,
      workSpaceId,
    }),
  editCustomField: (
    customFieldId: string,
    name: string,
    description: string,
    type: string,
    applicableFor: string,
    workSpaceId: string,
  ) =>
    requests.put('/customfields/edit', {
      customFieldId,
      name,
      description,
      type,
      applicableFor,
      workSpaceId,
    }),
  makeCustomFieldInactive: (customFieldId: string, workSpaceId: string) =>
    requests.put('/customfields/inactive', { customFieldId, workSpaceId }),
  makeCustomFieldActive: (customFieldId: string, workSpaceId: string) =>
    requests.put('/customfields/active', { customFieldId, workSpaceId }),
  deleteCustomField: (customFieldId: string, workSpaceId: string) =>
    requests.delete('/customfields/delete', { customFieldId, workSpaceId }),
  getOneCustomField: (workSpaceId: string, customFieldId: string) =>
    requests.get(
      `/customfields/id?customFieldId=${customFieldId}&workSpaceId=${workSpaceId}`,
    ),
};

const ClientGroups = {
  getClientGroupList: (
    workSpaceId: string,
    active: boolean,
    searchText: string,
    skip: number,
    limit: number,
  ) =>
    requests.get(
      `/group/list?workSpaceId=${workSpaceId}&active=${active}&searchText=${encodeURIComponent(
        searchText,
      )}&skip=${skip}&limit=${limit}`,
    ),
  addClientGroup: (name: string, description: string, workSpaceId: string) =>
    requests.post('/group/add', { name, description, workSpaceId }),
  editClientGroup: (
    name: string,
    description: string,
    id: string,
    workSpaceId: string,
  ) => requests.post('/group/edit', { id, name, description, workSpaceId }),
  makeClientGroupInactive: (workSpaceId: string, groupId: string) =>
    requests.post('/group/inactive', { workSpaceId, groupId }),
  makeClientGroupActive: (workSpaceId: string, groupId: string) =>
    requests.post('/group/active', { workSpaceId, groupId }),
  deleteClientGroup: (workSpaceId: string, groupId: string) =>
    requests.delete('/group/delete', { workSpaceId, groupId }),
  getParticularGroup: (groupId: string) => requests.get(`/group/${groupId}`),
  getOneGroup: (groupId: string, workSpaceId: string) =>
    requests.get(`/group/id?groupId=${groupId}&workSpaceId=${workSpaceId}`),
};

const Clients = {
  getClientList: (
    workSpaceId: string,
    skip: number,
    limit: number,
    searchText: string,
    active: boolean,
    filters?: any,
    download?: boolean,
  ) => {
    const query = JSON.stringify({
      skip,
      limit,
      searchText: encodeURIComponent(searchText.trim()),
      active,
      download,
      filters,
    });
    return download
      ? requests.getBlob(
          `/client/list?workSpaceId=${workSpaceId}&query=${query}`,
          { responseType: 'blob' },
        )
      : requests.get(`/client/list?workSpaceId=${workSpaceId}&query=${query}`);
  },
  getClientListForDropdown: (workSpaceId: string) =>
    requests.get(`/client/listForDropdown?workSpaceId=${workSpaceId}`),
  getOneClientById: (workSpaceId: string, clientId: string) =>
    requests.get(
      `/client/getClient?workSpaceId=${workSpaceId}&clientId=${clientId}`,
    ),
  getQRMPDetails: (workSpaceId: string, gstin: string) =>
    requests.post('/qrmp/qrmp', { workSpaceId, gstin }),
  addClient: (workSpaceId: string, data: any) =>
    requests.post('/client/add', { workSpaceId, ...data }),
  editClient: (workSpaceId: string, data: any, clientId: string) =>
    requests.post('/client/edit', { workSpaceId, clientId, ...data }),
  makeClientInactive: (workSpaceId: string, clientId: string) =>
    requests.post('/client/inactive', { workSpaceId, clientId }),
  makeClientActive: (workSpaceId: string, clientId: string) =>
    requests.post('/client/active', { workSpaceId, clientId }),
  deleteClient: (workSpaceId: string, clientId: string) =>
    requests.post('/client/delete', { workSpaceId, clientId }),
  getBulkUploadSheet: (workSpaceId: string) =>
    requests.getBlob(`/bulkupload/client/sheet?workSpaceId=${workSpaceId}`, {
      responseType: 'blob',
    }),
  getAllBulkUploadRequests: (workSpaceId: string) =>
    requests.get(`/bulkupload/client/requests?workSpaceId=${workSpaceId}`),
  getOnebulkUploadRequest: (workSpaceId: string, requestId: string) =>
    requests.get(
      `/bulkupload/client/request?workSpaceId=${workSpaceId}&requestId=${requestId}`,
    ),
  deleteImportRequest: (workSpaceId: string, requestId: string) =>
    requests.put(`/bulkupload/client/deleteReqStatus`, {
      workSpaceId,
      requestId,
    }),
  validateUpload: (body: any) =>
    requests.post(`/bulkupload/client/validateSheet`, body),
  getErrorColumns: (workSpaceId: string, requestId: string) =>
    requests.get(
      `/bulkupload/client/errColumns?workSpaceId=${workSpaceId}&requestId=${requestId}`,
    ),
  getColumnErrors: (workSpaceId: string, requestId: string, column: string) =>
    requests.get(
      `/bulkupload/client/columnError?workSpaceId=${workSpaceId}&requestId=${requestId}&column=${column}`,
    ),
  updateRowField: (
    workSpaceId: string,
    requestId: string,
    entryId: string,
    columnToUpdate: string,
    value: string,
  ) =>
    requests.post(`/bulkupload/client/fieldUpdate`, {
      workSpaceId,
      requestId,
      entryId,
      columnToUpdate,
      value,
    }),
  startImport: (workSpaceId: string, requestId: string) =>
    requests.post(`/bulkupload/client/changeReqStatus`, {
      workSpaceId,
      requestId,
    }),
  getErrorReport: (workSpaceId: string, requestId: string) =>
    requests.getBlob(
      `/bulkupload/client/errorReport?workSpaceId=${workSpaceId}&requestId=${requestId}`,
      { responseType: 'blob' },
    ),
  getGSTdetails: (workSpaceId: string, gstin: string) =>
    requests.get(`/gst?workSpaceId=${workSpaceId}&gstin=${gstin}`),
  generateExportSheet: (workSpaceId: string) =>
    requests.getBlob(`/client/exportSheet?workSpaceId=${workSpaceId}`, {
      responseType: 'blob',
    }),
  getGSTdetailsForPlan: (gstin: string) => {
    return requests.get(`/payment/gstinDetails?gstin=${gstin}`);
  },
  addAttachment: (formData: FormData) =>
    requests.post('/client/attachment', formData),
  editAttachment: (
    workSpaceId: string,
    attachmentId: string,
    description: string,
  ) =>
    requests.put('/client/attachment', {
      workSpaceId,
      attachmentId,
      description,
    }),
  listAttachments: (data: {
    workSpaceId: string;
    clientId?: string;
    skip: number;
    limit: number;
    fileType?: string;
    fromDate?: string;
    toDate?: string;
    sort?: string;
    tempId?: string;
  }) => requests.post(`/client/list/attachment`, data),
  deleteAttachment: (workSpaceId: string, attachmentId: string) =>
    requests.delete(
      `/client/attachment?workSpaceId=${workSpaceId}&attachmentId=${attachmentId}`,
    ),
  addTag: (workSpaceId: string, clientId: string, tagId: string) =>
    requests.post(
      `/client/addTag?workSpaceId=${workSpaceId}&clientId=${clientId}&tagId=${tagId}`,
    ),
  deleteTag: (workSpaceId: string, clientId: string, tagId: string) =>
    requests.delete(
      `/client/deleteTag?workSpaceId=${workSpaceId}&clientId=${clientId}&tagId=${tagId}`,
    ),
  addGroup: (workSpaceId: string, clientId: string, groupId: string) =>
    requests.post(
      `/client/addGroup?workSpaceId=${workSpaceId}&clientId=${clientId}&groupId=${groupId}`,
    ),
  deleteGroup: (workSpaceId: string, clientId: string, groupId: string) =>
    requests.delete(
      `/client/deleteGroup?workSpaceId=${workSpaceId}&clientId=${clientId}&groupId=${groupId}`,
    ),
  addPerson: (
    workSpaceId: string,
    clientId: string,
    personId: string,
    designation: string,
    isPrimary: boolean,
  ) =>
    requests.post(`/client/addPerson`, {
      workSpaceId,
      clientId,
      personId,
      designation,
      isPrimary,
    }),
  deletePerson: (workSpaceId: string, clientId: string, personId: string) =>
    requests.delete(
      `/client/deletePerson?workSpaceId=${workSpaceId}&clientId=${clientId}&personId=${personId}`,
    ),
};

const Tasks = {
  getTaskList: (
    workSpaceId: string,
    limit: number,
    skip: number,
    searchText: string,
    filters?: any,
    download?: boolean,
  ) => {
    // if task name contains special characters, encode them specially for &
    const filterQuery = JSON.parse(JSON.stringify(filters));
    for (const key in filterQuery) {
      if (key === 'name') {
        for (const indName in filterQuery[key]) {
          filterQuery[key][indName] = encodeURIComponent(
            filterQuery[key][indName],
          );
        }
      }
      if (key === 'currentUserName') {
        filterQuery[key] = encodeURIComponent(filterQuery[key]);
      }
    }

    const query = JSON.stringify({
      limit,
      skip,
      searchText: encodeURIComponent(searchText.trim()),
      download,
      filters: filterQuery,
    });
    return download
      ? requests.getBlob(
          `/task/list?workSpaceId=${workSpaceId}&query=${query}`,
          {
            responseType: 'blob',
          },
        )
      : requests.get(`/task/list?workSpaceId=${workSpaceId}&query=${query}`);
  },
  getTaskNamesList: (workSpaceId: string) =>
    requests.get(`/task/getalltasksforsearch?workSpaceId=${workSpaceId}`),
  taskTypeList: (workSpaceId: string, type?: string) =>
    requests.get(
      `/tasktypes/list?workSpaceId=${workSpaceId}&type=${type ? type : ''}`,
    ),
  listOtherTask: (workSpaceId: string) =>
    requests.get(`/task/listOtherTasks?workSpaceId=${workSpaceId}`),
  gstClientList: (
    workSpaceId: string,
    limit: number,
    skip: number,
    searchText: string,
    period: string,
    task: string,
    filters: any,
  ) =>
    requests.post('/task/getGstClients', {
      workSpaceId,
      limit,
      skip,
      searchText,
      period,
      task,
      filters,
    }),
  itClientList: (
    workSpaceId: string,
    limit: number,
    skip: number,
    searchText: string,
    period: string,
    task: string,
    filters: any,
  ) =>
    requests.post('/task/getItClients', {
      workSpaceId,
      limit,
      skip,
      searchText,
      period,
      task,
      filters,
    }),
  mcaClientList: (
    workSpaceId: string,
    limit: number,
    skip: number,
    searchText: string,
    period: string,
    task: string,
    filters: any,
  ) =>
    requests.post('/task/getMcaClients', {
      workSpaceId,
      limit,
      skip,
      searchText,
      period,
      task,
      filters,
    }),
  otherClientList: (workSpaceId: string, otherTaskList: any) =>
    requests.post('/task/listClientsForOtherTask', {
      workSpaceId,
      otherTaskList,
    }),
  createGstTask: (workSpaceId: string, data: any) =>
    requests.post('/task/createGstTask', { workSpaceId, ...data }),
  createItTask: (workSpaceId: string, data: any) =>
    requests.post('/task/createIncomeTaxTask', { workSpaceId, ...data }),
  createMcaTask: (workSpaceId: string, data: any) =>
    requests.post('/task/createMcaTask', { workSpaceId, ...data }),
  createOtherTask: (workSpaceId: string, data: any) =>
    requests.post('/task/createOtherTask', { workSpaceId, ...data }),
  addOtherTask: (workSpaceId: string, data: any) =>
    requests.post('/task/addOtherTask', { workSpaceId, ...data }),
  editOtherTask: (workSpaceId: string, id: string, data: any) =>
    requests.post('/task/editOtherTask', { id, workSpaceId, ...data }),
  deleteOtherTask: (workSpaceId: string, otherTaskId: string) =>
    requests.delete(`/task/deleteOtherTask`, { workSpaceId, otherTaskId }),
  changeTaskStar: (workSpaceId: string, taskIds: any, star: boolean) =>
    requests.post('/task/changeStar', { workSpaceId, taskIds, star }),
  changeDueDate: (workSpaceId: string, taskIds: any, dueDate: string) =>
    requests.post('/task/changeDueDate', { workSpaceId, taskIds, dueDate }),
  changeStatus: (workSpaceId: string, taskIds: any, statusId: string) =>
    requests.post('/task/changeStatus', { workSpaceId, taskIds, statusId }),
  changeCurrentUser: (
    workSpaceId: string,
    taskIds: any,
    currentUserId: string,
    actions: any,
  ) =>
    requests.post('/task/changeCurrentUser', {
      workSpaceId,
      taskIds,
      currentUserId,
      ...actions,
    }),
  updateTaskUsers: (
    workSpaceId: string,
    taskIds: any,
    userIds: any,
    actions: any,
  ) =>
    requests.post('/task/updateTaskUsers', {
      workSpaceId,
      taskIds,
      userIds,
      ...actions,
    }),
  getTaskDueDate: (workSpaceId: string, taskName: string, period: string) =>
    requests.get(
      `/tasktypes/duedate?workSpaceId=${workSpaceId}&taskName=${encodeURIComponent(
        taskName,
      )}&period=${period}`,
    ),
  deleteTask: (workSpaceId: string, taskIds: any) =>
    requests.delete('/task/deleteTasks', { workSpaceId, taskIds }),
  getTaskfromTaskId: (workSpaceId: string, taskId: string) =>
    requests.get(
      `/task/taskfromtaskid?workSpaceId=${workSpaceId}&taskId=${taskId}`,
    ),
  getTasksfromTaskIds: (workSpaceId: string, taskIds: string[]) =>
    requests.get(
      `/task/tasksfromtaskids?workSpaceId=${workSpaceId}${
        taskIds.length > 0
          ? `&${createSearchParams({ 'taskIds[]': taskIds })}`
          : ''
      }`,
    ),
  getTaskComment: (
    workSpaceId: string,
    taskId: string,
    limit: number,
    skip: number,
  ) =>
    requests.get(
      `/task/getComments?workSpaceId=${workSpaceId}&taskId=${taskId}&limit=${limit}&skip=${skip}`,
    ),
  changeDescription: (workSpaceId: string, taskIds: any, description: any) =>
    requests.post('/task/changeDescription', {
      workSpaceId,
      taskIds,
      description,
    }),
  addComment: (workSpaceId: string, taskId: string, comment: any) =>
    requests.post('/task/createComment', { workSpaceId, taskId, comment }),
  editComment: (workSpaceId: string, commentId: string, updatedComment: any) =>
    requests.put('/task/editComment', {
      workSpaceId,
      commentId,
      comment: updatedComment,
    }),
  deleteComment: (workSpaceId: string, commentId: string) =>
    requests.delete('/task/deleteComment', { workSpaceId, commentId }),
  addAttachment: (formData: FormData) =>
    requests.post('/task/attachment', formData),
  editAttachment: (
    workSpaceId: string,
    attachmentId: string,
    description: string,
  ) =>
    requests.put('/task/attachment', {
      workSpaceId,
      attachmentId,
      description,
    }),
  listAttachments: (data: {
    workSpaceId: string;
    taskId?: string;
    skip: number;
    limit: number;
    fileType?: string;
    fromDate?: string;
    toDate?: string;
    sort?: string;
  }) => requests.post(`/task/list/attachment`, data),
  deleteAttachment: (workSpaceId: string, attachmentId: string) =>
    requests.delete(
      `/task/attachment?workSpaceId=${workSpaceId}&attachmentId=${attachmentId}`,
    ),

  changeFeesTrackingStatus: (
    workSpaceId: string,
    taskIds: any[],
    feesStatus: string,
  ) =>
    requests.post('/task/changeFeesTrackingStatus', {
      workSpaceId,
      taskIds,
      feesStatus,
    }),
  getTasksNotCreated: (workSpaceId: string, taskType: string) =>
    requests.get(
      `/task/tasksnotcreated?workSpaceId=${workSpaceId}&taskType=${taskType}`,
    ),
};

const Gstin = {
  addGst: (gstin: string) => requests.post('/gsts', { gstin }),
  getAll: () => requests.get('/gsts'),
  getGst: (gstin: string) => requests.get(`/gsts/${gstin}`),
  changeName: (body: { id: string; name: string }) =>
    requests.put('/gsts/changeName', body),
  addmultipleGstin: (gstinArray: string[], workSpaceId: string) =>
    requests.post('/client/gstinimportrequest', { gstinArray, workSpaceId }),
  getRequestStatus: (workSpaceId: string, requestId: string) =>
    requests.get(
      `/client/gstinimportrequeststatus?workSpaceId=${workSpaceId}&requestId=${requestId}`,
    ),
};

// Requests for todos

const Todo = {
  addList: (list: any) => requests.post('/todo/addtodolist', list),
  getAllTodoOfList: (
    workSpaceId: string,
    toDoListId: string,
    todoStatus: string,
    searchText: string,
  ) =>
    requests.get(
      `/todo/todo?workSpaceId=${workSpaceId}&toDoListId=${toDoListId}&status=${todoStatus}&searchText=${encodeURIComponent(
        searchText,
      )}`,
    ),
  todoSpecificCases: (
    workSpaceId: string,
    type: string,
    todoStatus: string,
    searchText: string,
  ) =>
    requests.get(
      `/todo/todospecificcases?workSpaceId=${workSpaceId}&status=${todoStatus}&type=${type}&searchText=${encodeURIComponent(
        searchText,
      )}`,
    ),
  count: (workSpaceId: string) =>
    requests.get(`/todo/counttodospecificcases?workSpaceId=${workSpaceId}`),
  getAllTodoListOfUser: (workSpaceId: string) =>
    requests.get(`/todo/todolist?workSpaceId=${workSpaceId}`),
  create: (todo: any) => requests.post('/todo/addtodo', todo),
  markAsComplete: (todo: any) => requests.post(`/todo/marktodocompleted`, todo),
  markPending: (todo: any) => requests.post(`/todo/marktodopending`, todo),
  edit: (todo: any) => requests.post('/todo/edittodo', todo),
  delete: (todo: any) => requests.post(`/todo/deletetodo`, todo),
  addUserToTodoList: (user: any) =>
    requests.post(`/todo/addusertodolist`, user),
  todoListRename: (list: any) =>
    requests.post(`/todo/todolistchangename`, list),
  deleteList: (workSpaceId: string, toDoListId: string) =>
    requests.post(`/todo/deletetodolist`, { workSpaceId, toDoListId }),
  reorder: (workSpaceId: string, toDoId: string, order: number) =>
    requests.post(`/todo/reordertodo`, {
      workSpaceId,
      toDoId,
      order,
    }),
};

// Requests for Receipts and Payments

const Receipt = {
  getSourceList: (
    workSpaceId: string,
    status: boolean,
    searchText: string,
    skip: number,
    limit: number,
  ) =>
    requests.get(
      `/receiptpayment/listsource?workSpaceId=${workSpaceId}&active=${status}&searchText=${encodeURIComponent(
        searchText,
      )}&skip=${skip}&limit=${limit}`,
    ),
  addSource: (name: string, description: string, workSpaceId: string) =>
    requests.post('/receiptpayment/addsource', {
      name,
      description,
      workSpaceId,
    }),
  editSource: (
    sourceId: string,
    name: string,
    description: string,
    workSpaceId: string,
  ) =>
    requests.post('/receiptpayment/editsource', {
      sourceId,
      name,
      description,
      workSpaceId,
    }),
  makeSourceActive: (sourceId: string, workSpaceId: string) =>
    requests.post('/receiptpayment/activesource', { sourceId, workSpaceId }),
  makeSourceInActive: (sourceId: string, workSpaceId: string) =>
    requests.post('/receiptpayment/inactivesource', { sourceId, workSpaceId }),
  getCategoryList: (
    workSpaceId: string,
    status: boolean,
    searchText: string,
    skip: number,
    limit: number,
  ) =>
    requests.get(
      `/receiptpayment/listcategory?workSpaceId=${workSpaceId}&active=${status}&searchText=${encodeURIComponent(
        searchText,
      )}&skip=${skip}&limit=${limit}`,
    ),
  addCategory: (name: string, description: string, workSpaceId: string) =>
    requests.post('/receiptpayment/addcategory', {
      name,
      description,
      workSpaceId,
    }),
  editCategory: (
    categoryId: string,
    name: string,
    description: string,
    workSpaceId: string,
  ) =>
    requests.post('/receiptpayment/editcategory', {
      categoryId,
      name,
      description,
      workSpaceId,
    }),
  makeCategoryActive: (categoryId: string, workSpaceId: string) =>
    requests.post('/receiptpayment/activecategory', {
      categoryId,
      workSpaceId,
    }),
  makeCategoryInActive: (categoryId: string, workSpaceId: string) =>
    requests.post('/receiptpayment/inactivecategory', {
      categoryId,
      workSpaceId,
    }),
  getReceiptList: (
    workSpaceId: string,
    skip: number,
    limit: number,
    dateFrom: string,
    dateTo: string,
    type: string,
    notes: string,
    amountFrom: string,
    amountTo: string,
    clientId: string,
    sourceId: string,
    categoryId: string,
    download: boolean,
  ) => {
    const url = `/receiptpayment/listreceiptpayment?workSpaceId=${workSpaceId}&skip=${skip}&limit=${limit}&dateFrom=${dateFrom}&dateTo=${dateTo}&type=${type}&notes=${encodeURIComponent(
      notes,
    )}&amountFrom=${amountFrom}&amountTo=${amountTo}${
      clientId !== '' ? '&clientId[]=' + clientId : ''
    }${sourceId !== '' ? '&sourceId[]=' + sourceId : ''}${
      categoryId !== '' ? '&categoryId[]=' + categoryId : ''
    }&download=${download}`;
    return download
      ? requests.getBlob(url, { responseType: 'blob' })
      : requests.get(url);
  },
  addReceipt: (receipt: any) => requests.post('/receiptpayment/add', receipt),
  editReceipt: (receipt: any) => requests.post('/receiptpayment/edit', receipt),
  deleteReceipt: (workSpaceId: string, receiptId: string) =>
    requests.delete(
      `/receiptpayment/deleteentry?workSpaceId=${workSpaceId}&entryId=${receiptId}`,
    ),
  pendingBalanceReport: (workSpaceId: string, download: boolean) => {
    const url = `/receiptpayment/pedingbalancereport?workSpaceId=${workSpaceId}&download=${download}`;
    return download
      ? requests.getBlob(url, { responseType: 'blob' })
      : requests.get(url);
  },
  clientWiseReport: (
    workSpaceId: string,
    clientId: string,
    download: boolean,
  ) => {
    const url = `/receiptpayment/clientreport?workSpaceId=${workSpaceId}&clientId=${clientId}&skip=0&limit=10&download=${download}`;
    return download
      ? requests.getBlob(url, { responseType: 'blob' })
      : requests.get(url);
  },
};

const RecurringTask = {
  getTaskTypes: (workSpaceId: string, type: string, applicableFor: string) =>
    requests.get(
      `/tasktypes/list?workSpaceId=${workSpaceId}&type=${type}&applicableFor=${applicableFor}&recurringTasks=${true}`,
    ),
  addRecurringTask: (
    workSpaceId: string,
    tasktype: string,
    beforeAfter: string,
    days: number,
    active: boolean,
  ) =>
    requests.post('/recurringtasks/add', {
      workSpaceId,
      tasktype,
      beforeAfter,
      days,
      active,
    }),
  getRecurringTaskSettings: (workSpaceId: string, type: string) =>
    requests.get(
      `/recurringtasks/getrecurringtask?workSpaceId=${workSpaceId}&type=${type}`,
    ),
};

const Logs = {
  getTagLogs: (workSpaceId: string, tagId: string) => {
    return requests.get(
      `/logs/tagLogs?workSpaceId=${workSpaceId}&tagId=${tagId}`,
    );
  },
  getClientLogs: (workSpaceId: string, clientId: string) => {
    return requests.get(
      `/logs/clientLogs?workSpaceId=${workSpaceId}&clientId=${clientId}`,
    );
  },
  getStatusLogs: (workSpaceId: string, statusId: string) => {
    return requests.get(
      `/logs/statusLogs?workSpaceId=${workSpaceId}&statusId=${statusId}`,
    );
  },
  getCustomFieldLogs: (workSpaceId: string, customFieldId: string) => {
    return requests.get(
      `/logs/customFieldLogs?workSpaceId=${workSpaceId}&customFieldId=${customFieldId}`,
    );
  },
  getUserLogs: (
    workSpaceId: string,
    userId: string,
    startDate: string,
    endDate: string,
  ) => {
    return requests.get(
      `/logs/userLogs?workSpaceId=${workSpaceId}&userId=${userId}&startDate=${startDate}&endDate=${endDate}`,
    );
  },
  getGroupLogs: (workSpaceId: string, groupId: string) => {
    return requests.get(
      `/logs/groupLogs?workSpaceId=${workSpaceId}&groupId=${groupId}`,
    );
  },
  getContactPersonLogs: (workSpaceId: string, contactPersonId: string) => {
    return requests.get(
      `/logs/contactPersonLogs?workSpaceId=${workSpaceId}&contactPersonId=${contactPersonId}`,
    );
  },
  getTaskLogs: (workSpaceId: string, taskId: string) => {
    return requests.get(
      `/logs/taskLogs?workSpaceId=${workSpaceId}&taskId=${taskId}`,
    );
  },
};

const Reports = {
  getTaskLogsReport: (
    workSpaceId: string,
    dateFrom: string,
    dateTo: string,
    skip: number,
    limit: number,
    download: boolean,
    type: ChangeType[],
    userIds: string[],
    clientIds: string[],
  ) => {
    const baseURL = `/logs/taskLogsReport?workSpaceId=${workSpaceId}&dateFrom=${dateFrom}&dateTo=${dateTo}&skip=${skip}&limit=${limit}&download=${download}`;

    const userIdParams =
      userIds.length > 0
        ? `&${createSearchParams({ 'userIds[]': userIds })}`
        : '';
    const clientIdParams =
      clientIds.length > 0
        ? `&${createSearchParams({ 'clientIds[]': clientIds })}`
        : '';
    const typeParams =
      type.length > 0 ? `&${createSearchParams({ 'type[]': type })}` : '';

    const url = `${baseURL + typeParams + userIdParams + clientIdParams}`;

    return download
      ? requests.getBlob(url, { responseType: 'blob' })
      : requests.get(url);
  },
  getSettingsLogsReport: (
    workSpaceId: string,
    dateFrom: string,
    dateTo: string,
    skip: number,
    limit: number,
    download: boolean,
    logType: LogType[],
    type: ChangeType[],
    userIds?: string[],
    clientIds?: string[],
  ) => {
    const baseURL = `/logs/settingsLogsReport?workSpaceId=${workSpaceId}&dateFrom=${dateFrom}&dateTo=${dateTo}&skip=${skip}&limit=${limit}&download=${download}`;

    const logTypeParams =
      logType?.length > 0
        ? `&${createSearchParams({ 'logType[]': logType })}`
        : '';

    const userIdParams =
      userIds && userIds.length > 0
        ? `&${createSearchParams({ 'userIds[]': userIds })}`
        : '';
    const clientIdParams =
      clientIds && clientIds.length > 0
        ? `&${createSearchParams({ 'clientIds[]': clientIds })}`
        : '';
    const typeParams =
      type?.length > 0 ? `&${createSearchParams({ 'type[]': type })}` : '';

    const url = `${
      baseURL + logTypeParams + typeParams + userIdParams + clientIdParams
    }`;

    return download
      ? requests.getBlob(url, { responseType: 'blob' })
      : requests.get(url);
  },
};

const dashboard = {
  getDueDaysReport: (
    workSpaceId: string,
    taskType: string,
    user: string,
    userType: string,
    userRights: string,
  ) => {
    return requests.get(
      `/dashboard/duedays?workSpaceId=${workSpaceId}&taskType=${taskType}&user=${user}&userType=${userType}&userRights=${userRights}`,
    );
  },
  getTaskBasedOnDueDays: (
    workSpaceId: string,
    taskType: string,
    user: string,
    userType: string,
    userRights: string,
    dueType: string,
  ) => {
    return requests.get(
      `/dashboard/gettaskonbasisofduedays?workSpaceId=${workSpaceId}&taskType=${taskType}&user=${user}&userType=${userType}&userRights=${userRights}&dueType=${dueType}`,
    );
  },
  statuswiseReport: (
    workSpaceId: string,
    taskType: string,
    user: string,
    userType: string,
    userRights: string,
  ) => {
    return requests.get(
      `/dashboard/statuswisereport?workSpaceId=${workSpaceId}&taskType=${taskType}&user=${user}&userType=${userType}&userRights=${userRights}`,
    );
  },
  userwiseReport: (workSpaceId: string, taskType: string, userType: string) => {
    return requests.get(
      `/dashboard/userwisereport?workSpaceId=${workSpaceId}&taskType=${taskType}&userType=${userType}`,
    );
  },
  statuswiseAndTaskwiseReport: (
    workSpaceId: string,
    taskType: string,
    user: string,
    userType: string,
    userRights: string,
    task: string,
    period: string,
  ) => {
    return requests.get(
      `/dashboard/statuswiseandtaskwisereport?workSpaceId=${workSpaceId}&taskType=${taskType}&task=${encodeURIComponent(
        task,
      )}&period=${period}&user=${user}&userType=${userType}&userRights=${userRights}`,
    );
  },
  taskwiseAndPeriodwiseReport: (
    workSpaceId: string,
    taskType: string,
    user: string,
    userType: string,
    userRights: string,
  ) => {
    return requests.get(
      `/dashboard/taskwiseperiodwisereport?workSpaceId=${workSpaceId}&taskType=${taskType}&user=${user}&userType=${userType}&userRights=${userRights}`,
    );
  },
  statuswiseAndUserwiseReport: (
    workSpaceId: string,
    taskType: string,
    user: string,
    userType: string,
    userRights: string,
    task: string,
    period: string,
  ) => {
    return requests.get(
      `/dashboard/statuswiseanduserwisereport?workSpaceId=${workSpaceId}&taskType=${taskType}&task=${encodeURIComponent(
        task,
      )}&period=${period}&user=${user}&userType=${userType}&userRights=${userRights}`,
    );
  },
  assignedTaskReport: (workSpaceId: string, taskType: string) => {
    return requests.get(
      `/dashboard/assignedtaskreport?workSpaceId=${workSpaceId}&taskType=${taskType}`,
    );
  },
};

const QRMP = {
  getGstinQrmpList: (
    workSpaceId: string,
    skip: number,
    limit: number,
    searchText: string,
    quarter: string,
    status: string,
    download: boolean,
  ) => {
    const url = `/qrmp/listqrmp?workSpaceId=${workSpaceId}&skip=${skip}&limit=${limit}&searchText=${encodeURIComponent(
      searchText,
    )}&quarter=${quarter}${
      status ? `&status[]=${status}` : ''
    }&download=${download}`;
    return download
      ? requests.getBlob(url, { responseType: 'blob' })
      : requests.get(url);
  },
  updateGstinQrmp: (workSpaceId: string, quarter: string, clientIds: any) => {
    return requests.put(`/qrmp/updateqrmp`, {
      workSpaceId,
      quarter,
      clientIds,
    });
  },
  getQrmpUpdateRequestDetails: (
    workSpaceId: string,
    importRequestId: string,
  ) => {
    return requests.get(
      `/qrmp/importrequestdetails?workSpaceId=${workSpaceId}&importRequestId=${importRequestId}`,
    );
  },
};

const SystemNotification = {
  getSystemNotification: (workSpaceId: string) => {
    return requests.get(`/systemnotification/list?workSpaceId=${workSpaceId}`);
  },
};

const Notifications = {
  getNotificationsPreferences: (workSpaceId: string) => {
    return requests.get(`/notification/setting?workSpaceId=${workSpaceId}`);
  },
  changePreferences: (
    workSpaceId: string,
    notificationPreferences: NotificationSettingsType,
  ) => {
    return requests.put(`/notification/setting`, {
      workSpaceId,
      ...notificationPreferences,
    });
  },
  listInAppNotifications: (
    workSpaceId: string,
    read: boolean,
    skip: number,
    limit: number,
  ) => {
    return requests.get(
      `/notification/list?workSpaceId=${workSpaceId}&skip=${skip}&limit=${limit}&read=${read}`,
    );
  },
  markAsRead: (workSpaceId: string, notificationId: string) => {
    return requests.put(`/notification/markAsRead`, {
      workSpaceId,
      notificationId,
    });
  },
  markAsUnread: (workSpaceId: string, notificationId: string) => {
    return requests.put(`/notification/markAsUnread`, {
      workSpaceId,
      notificationId,
    });
  },
  markAllAsRead: (workSpaceId: string) => {
    return requests.put(`/notification/markAllAsRead`, { workSpaceId });
  },
  deleteNotification: (workSpaceId: string, notificationIds: string[]) => {
    return requests.delete(`/notification/delete`, {
      workSpaceId,
      notificationIds,
    });
  },
};

const Messages = {
  getTemplatesList: (
    workSpaceId: string,
    type: MessageType,
    limit: number,
    skip: number,
  ) => {
    return requests.get(
      `/messages/getMessageTemplates?workSpaceId=${workSpaceId}&type=${type}&limit=${limit}&skip=${skip}`,
    );
  },
  createTemplate: (
    workSpaceId: string,
    name: string,
    type: MessageType,
    template: string,
    emailSubject?: string,
    emailHtml?: string,
  ) => {
    return type === 'EMAIL'
      ? requests.post(`/messages/createMessageTemplate`, {
          workSpaceId,
          name,
          type,
          template,
          emailSubject,
          emailHtml,
        })
      : requests.post(`/messages/createMessageTemplate`, {
          workSpaceId,
          name,
          type,
          template,
        });
  },
  updateTemplate: (
    workSpaceId: string,
    name: string,
    type: MessageType,
    messageId: string,
    template: string,
    emailSubject?: string,
    emailHtml?: string,
  ) => {
    return type === 'EMAIL'
      ? requests.post(`/messages/editMessageTemplate`, {
          workSpaceId,
          name,
          template,
          emailSubject,
          emailHtml,
          type,
          messageId,
        })
      : requests.post(`/messages/editMessageTemplate`, {
          workSpaceId,
          name,
          template,
          type,
          messageId,
        });
  },
  deleteTemplate: (workSpaceId: string, messageId: string) => {
    return requests.delete(`/messages/deleteMessageTemplate`, {
      workSpaceId,
      messageId,
    });
  },
  getClientsForMessage: (
    workSpaceId: string,
    limit: number,
    skip: number,
    searchText: string,
    period: string,
    task: string,
    taskType: TaksType,
    filters: any,
  ) => {
    return period
      ? requests.post(`/messages/clientsForMessages`, {
          workSpaceId,
          limit,
          skip,
          searchText,
          period,
          task,
          taskType,
          filters,
        })
      : requests.post(`/messages/clientsForMessages`, {
          workSpaceId,
          limit,
          skip,
          searchText,
          filters,
        });
  },
};

const TimeTracking = {
  start: (workSpaceId: string, taskId: string) => {
    return requests.post(`/timetrack/starttime`, {
      workSpaceId,
      taskId,
    });
  },
  stop: (workSpaceId: string, id: string, comment: string) => {
    return requests.post(`/timetrack/stoptime/`, {
      workSpaceId,
      id,
      comment,
    });
  },
  editComment: (workSpaceId: string, timetrackId: string, comment: string) => {
    return requests.put(`/timetrack/editcomment`, {
      workSpaceId,
      timetrackId,
      comment,
    });
  },
  deleteTimeTracking: (workSpaceId: string, timeTrackingId: string) => {
    return requests.delete(
      `/timetrack/deletetimetrack?workSpaceId=${workSpaceId}&id=${timeTrackingId}`,
    );
  },
  manualTracking: (
    workSpaceId: string,
    taskId: string,
    datas: {
      startTime: string;
      stopTime: string;
      totalTime: number;
      comment: string;
    }[],
  ) => {
    return requests.post(`/timetrack/manualtime`, {
      workSpaceId,
      taskId,
      datas,
    });
  },
  currentlyWorking: (workSpaceId: string) => {
    return requests.get(
      `/timetrack/currentlyworking?workSpaceId=${workSpaceId}`,
    );
  },
  getTrackingHistory: (
    workSpaceId: string,
    dateFrom: string,
    dateTo: string,
    skip: number,
    limit: number,
    download: boolean,
    clientIdsArray?: string[],
    userIdsArray?: string[],
    trackingType?: string,
    taskIds?: string[],
  ) => {
    const baseURL = `/timetrack/gettimetrackingentries?workSpaceId=${workSpaceId}&dateFrom=${dateFrom}&dateTo=${dateTo}&skip=${skip}&limit=${limit}&download=${download}`;

    const userIdParams =
      userIdsArray && userIdsArray.length > 0
        ? `&${createSearchParams({ 'userIds[]': userIdsArray })}`
        : '';
    const clientIdParams =
      clientIdsArray && clientIdsArray.length > 0
        ? `&${createSearchParams({ 'clientIds[]': clientIdsArray })}`
        : '';
    const typeParams = trackingType
      ? `&${createSearchParams({ type: trackingType })}`
      : '';
    const taskParams =
      taskIds && taskIds?.length > 0
        ? `&${createSearchParams({ 'taskIds[]': taskIds })}`
        : '';

    return download
      ? requests.getBlob(
          `${baseURL}${clientIdParams}${userIdParams}${typeParams}${taskParams}`,
          { responseType: 'blob' },
        )
      : requests.get(
          `${baseURL}${clientIdParams}${userIdParams}${typeParams}${taskParams}`,
        );
  },
  // getTrackingHistoryForTask: (
  //   workSpaceId: string,
  //   taskId: string,
  //   download: boolean
  // ) => {
  //   return download
  //     ? requests.getBlob(
  //         `/timetrack/alltimetrackingentries?workSpaceId=${workSpaceId}&taskId=${taskId}&download=${download}`,
  //         {
  //           responseType: "blob"
  //         }
  //       )
  //     : requests.get(
  //         `/timetrack/alltimetrackingentries?workSpaceId=${workSpaceId}&taskId=${taskId}&download=${download}`
  //       );
  // }
};

const RegisterInOut = {
  addRegisterEntry: (
    workSpaceId: string,
    date: string,
    client: string,
    type: RegisterType,
    document: string,
    mode: RegisterMode,
    manner: string,
    isOriginal: boolean,
    isReturnable: boolean,
    keptAt: string,
    receivedBy: string,
    futureActionRequire: boolean,
    description: string,
  ) => {
    const data_client = client ? { client } : {};
    const data_type = type ? { type } : {};
    const data_document = document ? { document } : {};
    const data_mode = mode ? { mode } : {};
    const data_manner = manner ? { manner } : {};
    const data_keptAt = keptAt ? { keptAt } : {};
    const data_receivedBy = receivedBy ? { receivedBy } : {};
    const data_description = description ? { description } : {};

    return requests.post(`/registerinout/addRegisterInOutEntry`, {
      workSpaceId,
      date,
      ...data_client,
      ...data_type,
      ...data_document,
      ...data_mode,
      ...data_manner,
      ...data_keptAt,
      ...data_receivedBy,
      ...data_description,
      isOriginal,
      isReturnable,
      futureActionRequire,
    });
  },
  editRegisterEntry: (
    workSpaceId: string,
    registerId: string,
    date: string,
    client: string,
    type: RegisterType,
    document: string,
    mode: RegisterMode,
    manner: string,
    isOriginal: boolean,
    isReturnable: boolean,
    keptAt: string,
    receivedBy: string,
    futureActionRequire: boolean,
    description: string,
  ) => {
    const data_client = client ? { client } : {};
    const data_type = type ? { type } : {};
    const data_document = document ? { document } : {};
    const data_mode = mode ? { mode } : {};
    const data_manner = manner ? { manner } : {};
    const data_keptAt = keptAt ? { keptAt } : {};
    const data_receivedBy = receivedBy ? { receivedBy } : {};
    const data_description = description ? { description } : {};

    return requests.post(
      `/registerinout/editRegisterInOut/${registerId}?workSpaceId=${workSpaceId}`,
      {
        date,
        ...data_client,
        ...data_type,
        ...data_document,
        ...data_mode,
        ...data_manner,
        ...data_keptAt,
        ...data_receivedBy,
        ...data_description,
        isOriginal,
        isReturnable,
        futureActionRequire,
      },
    );
  },
  deleteRegisterEntry: (registerId: string, workSpaceId: string) => {
    return requests.delete(
      `/registerinout/deleteRegisterInOut?id=${registerId}&workSpaceId=${workSpaceId}`,
    );
  },
  getRegisterEntries: (
    workSpaceId: string,
    download: boolean,
    limit: number,
    skip: number,
    dateFrom: string,
    dateTo: string,
    client: string,
    type: RegisterType,
    document: string,
    mode: '' | 'Hard Copy' | 'Soft Copy',
    manner: string,
    receivedBy: string,
    isOriginal: BooleanString,
    isReturnable: BooleanString,
    keptAt: string,
    futureActionRequire: BooleanString,
    description: string,
  ) => {
    const url = `/registerinout/registerInOutList?workSpaceId=${workSpaceId}&download=${download}&limit=${limit}&skip=${skip}${
      dateFrom ? `&dateFrom=${dateFrom}` : ''
    }${dateTo ? `&dateTo=${dateTo}` : ''}${
      client ? `&client=${client}` : ''
    }${type ? `&type=${type}` : ''}${
      document ? `&document=${document}` : ''
    }${mode ? `&mode=${mode}` : ''}${manner ? `&manner=${manner}` : ''}${
      receivedBy ? `&receivedBy=${receivedBy}` : ''
    }${isOriginal !== null ? `&isOriginal=${isOriginal}` : ''}${
      isReturnable !== null ? `&isReturnable=${isReturnable}` : ''
    }${keptAt ? `&keptAt=${keptAt}` : ''}${
      futureActionRequire !== null
        ? `&futureActionRequire=${futureActionRequire}`
        : ''
    }${description ? `&description=${encodeURIComponent(description)}` : ''}`;

    return download
      ? requests.getBlob(url, {
          responseType: 'blob',
        })
      : requests.get(url);
  },
  addDocument: (workSpaceId: string, name: string) => {
    return requests.post(`/registerinout/addDocumentType`, {
      name,
      workSpaceId,
    });
  },
  editDocument: (workSpaceId: string, id: string, name: string) => {
    return requests.post(`/registerinout/editDocumentType`, {
      name,
      workSpaceId,
      id,
    });
  },
  deleteDocument: (workSpaceId: string, documentId: string) => {
    return requests.delete(
      `/registerinout/deleteDocumentType?workSpaceId=${workSpaceId}&id=${documentId}`,
    );
  },
  listDocuments: (
    workSpaceId: string,
    searchText: string,
    skip: number,
    limit: number,
  ) => {
    return requests.get(
      `/registerinout/listDocumentType?workSpaceId=${workSpaceId}&searchText=${encodeURIComponent(
        searchText,
      )}&skip=${skip}&limit=${limit}`,
    );
  },
  addKeptAt: (workSpaceId: string, name: string) => {
    return requests.post(`/registerinout/addKeptAt`, { name, workSpaceId });
  },
  editKeptAt: (workSpaceId: string, id: string, name: string) => {
    return requests.post(`/registerinout/editKeptAt`, {
      name,
      workSpaceId,
      id,
    });
  },
  deleteKeptAt: (workSpaceId: string, keptAtId: string) => {
    return requests.delete(
      `/registerinout/deleteKeptAt?workSpaceId=${workSpaceId}&id=${keptAtId}`,
    );
  },
  listKeptAt: (
    workSpaceId: string,
    searchText: string,
    skip: number,
    limit: number,
  ) => {
    return requests.get(
      `/registerinout/listKeptAt?workSpaceId=${workSpaceId}&searchText=${encodeURIComponent(
        searchText,
      )}&skip=${skip}&limit=${limit}`,
    );
  },
};

const Payment = {
  getCurrentSubscription: (workSpaceId: string) => {
    return requests.get(
      `/payment/currentsubscription?workSpaceId=${workSpaceId}`,
    );
  },
  getPlansList: (workSpaceId: string, purchaseType: PurchaseType) => {
    return requests.get(
      `/payment/list?purchaseType=${purchaseType}&workSpaceId=${workSpaceId}`,
    );
  },
  getCalculatedPrice: (
    workSpaceId: string,
    planId: string,
    purchaseType: PurchaseType,
  ) => {
    return requests.get(
      `/payment/calculateprice?workSpaceId=${workSpaceId}&planId=${planId}&purchaseType=${purchaseType}`,
    );
  },
  initializePayment: (requestBody: {
    workSpaceId: string;
    planId: string;
    paymentType: PurchaseType;
    amount: number;
    GSTIN?: string;
    legalName?: string;
    address?: string;
    expiryDate: Date;
  }) => {
    return requests.post(`/payment`, requestBody);
  },
  initUpgradeStoragePayment: (workSpaceId: string, storage: number) => {
    return requests.post(`/payment/upgrade-storage`, {
      workSpaceId,
      storage,
    });
  },
  convertToFreePlan: (workSpaceId: string) => {
    return requests.post(`/payment/convertfreeplan`, { workSpaceId });
  },
  getOrderList: (workSpaceId: string) => {
    return requests.get(`/order/orderlist?workSpaceId=${workSpaceId}`);
  },
  getPaymentStatus: (order: string) => requests.get(`/order/?orderId=${order}`),
  getClientLimits: (workSpaceId: string) => {
    return requests.get(`/payment/clientlimits?workSpaceId=${workSpaceId}`);
  },
  getInvoices: (workSpaceId: string) => {
    return requests.get(`/payment/getinvoices?workSpaceId=${workSpaceId}`);
  },
  downloadInvoice: (workSpaceId: string, invoiceId: string) => {
    return requests.getBlob(
      `/payment/downloadinvoice?workSpaceId=${workSpaceId}&invoiceId=${invoiceId}`,
      { responseType: 'blob' },
    );
  },
  updateOrder: (data: {
    orderId: string;
    razorpayPaymentId: string;
    razorpayOrderId: string;
    razorpaySignature: string;
  }) => {
    return requests.patch(`/payment/updateorder`, data);
  },
};

const FrontendVersion = {
  getFrontendVersion: () => {
    return requests.get(`/version/frontend`);
  },
  getAlert: () => {
    return requests.get(`/version/alert`);
  },
};

const FeesTracking = {
  getFeesTrackingReport: (
    workSpaceId: string,
    dateFrom: string,
    dateTo: string,
    clients: string[],
    billTypes: string[],
    taskStatusTypes: string[],
    download: boolean,
    skip: number,
    limit: number,
  ) => {
    const baseURL = `/feestracking/getFeesTrackingReport?workSpaceId=${workSpaceId}&dateFrom=${dateFrom}&dateTo=${dateTo}&clients[]=${clients}&billTypes[]=${billTypes}&taskStatusTypes[]=${taskStatusTypes}&download=${download}&skip=${skip}&limit=${limit}`;

    return download
      ? requests.getBlob(baseURL, { responseType: 'blob' })
      : requests.get(baseURL);
  },
};

const ChangeLog = {
  getUnreadChangeLog: (userId: string) => {
    return axios.post(
      `https://api.produktly.com/api/client/changelogs/unread`,
      { userId },
      {
        headers: {
          'X-Client-Auth-Token': import.meta.env.VITE_APP_PRODUKTLY_AUTH_TOKEN,
        },
      },
    );
  },
};

const joinToWaitingList = (email: string) => {
  return requests.get(`/version/waitinglist?email=${email}`);
};

const agent = {
  Agency,
  Auth,
  Firm,
  Tag,
  Status,
  User,
  ContactPerson,
  CustomField,
  ClientGroups,
  Clients,
  Tasks,
  Gstin,
  Todo,
  Receipt,
  RecurringTask,
  Logs,
  Reports,
  dashboard,
  QRMP,
  Notifications,
  SystemNotification,
  Messages,
  TimeTracking,
  RegisterInOut,
  Payment,
  FrontendVersion,
  FeesTracking,
  joinToWaitingList,
  Ticket,
  ChangeLog,
};

export default agent;
