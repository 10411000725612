import { format } from 'date-fns';

export const formatDate = (date: any, reversed: boolean) => {
  const formattedDate = new Date(date);
  if (formattedDate.toLocaleString() !== 'Invalid Date') {
    return reversed
      ? format(formattedDate, 'yyyy-MM-dd')
      : format(formattedDate, 'dd-MM-yyyy');
  } else {
    return date;
  }
};

export const formatDateAndTime = (date: any, localIso?: boolean) => {
  const formattedDate = new Date(date);
  if (formattedDate.toLocaleString() !== 'Invalid Date') {
    return localIso
      ? format(formattedDate, "yyyy-MM-dd'T'HH:mm")
      : format(formattedDate, 'dd-MM-yyyy p');
  } else {
    return date;
  }
};

export const formatTime = (date: any) => {
  const formattedDate = new Date(date);
  if (formattedDate.toLocaleString() !== 'Invalid Date') {
    return format(formattedDate, 'dd MMM yyyy p');
  } else {
    return date;
  }
};

export const formatDateTimeString = (dateTime: string | Date) => {
  return new Date(dateTime).toLocaleDateString('en-In', {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  });
};

export const formatDateAsLocalString = (dateToFormat: string | Date) => {
  const date = new Date(dateToFormat).getUTCDate();
  const month = new Date(dateToFormat).getUTCMonth() + 1;
  const year = new Date(dateToFormat).getUTCFullYear();
  return `${date}/${month}/${year}`;
};
