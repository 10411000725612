import {
  Dialog,
  DialogBackdrop,
  Transition,
  TransitionChild,
} from '@headlessui/react';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import agent from '../../agent';
import Icon from '../../components/Icon';
import { onNotify } from '../../store/reducers';
import { RootState } from '../../store/store.ts';
import Notification from '../../components/Notification';

interface Props {
  onLoad: (forSearch: boolean) => void;
  editModalSetOpen?: (open: boolean) => void;
  state: {
    showEditModal: boolean;
    selectedRow: {
      _id: string;
      comment: string;
    };
  };
}

interface State {
  loading: boolean;
  comment: string;
}

const EditCommentModal = (props: Props) => {
  const commonState = useSelector((state: RootState) => state.common);
  const initialState = {
    loading: false,
    comment: props.state.selectedRow.comment,
  };
  const [state, setState] = useState<State>(initialState);

  const dispatch = useDispatch();

  useEffect(() => {
    const onKeyUpFunction = (event: any) => {
      if (event.key === 'Escape') {
        setOpen(false);
      }

      if (event.key === 'Enter') {
        editComment();
      }
    };

    document.addEventListener('keydown', onKeyUpFunction, false);

    return () => {
      document.removeEventListener('keydown', onKeyUpFunction, false);
    };
  }, []);

  const setOpen = (open: boolean) => {
    (props as any).editModalSetOpen(open);
  };

  const onLoad = () => {
    props?.onLoad(false);
  };

  const handleCommentChange = (ev: ChangeEvent<HTMLTextAreaElement>) => {
    setState((prev) => ({ ...prev, comment: ev.target.value }));
  };

  const editComment = () => {
    const { _id } = props.state.selectedRow;
    const workSpaceId = commonState?.currentFirm._id;

    if (_id && state.comment) {
      setState((prev) => ({ ...prev, loading: true }));

      agent.TimeTracking.editComment(workSpaceId, _id, state.comment)
        .then(() => {
          onLoad();
          setOpen(false);
          dispatch(
            onNotify({
              title: 'Success',
              message: 'Comment edited successfully',
              type: 'success',
            }),
          );
        })
        .catch((err: any) => {
          setState((prev) => ({ ...prev, loading: false }));
          dispatch(
            onNotify({
              title: 'Error',
              message:
                typeof err?.response?.data?.message === 'object'
                  ? 'Something went wrong : Could not save comment'
                  : err?.response?.data?.message ||
                    err?.response?.data?.error ||
                    err?.message,
              type: 'danger',
            }),
          );
        });
    } else {
      dispatch(
        onNotify({
          title: 'Error',
          message: 'Please enter a comment',
          type: 'danger',
        }),
      );
    }
  };

  return (
    <>
      <Transition show={props?.state?.showEditModal} as={Fragment} appear>
        <Dialog
          as='div'
          className='fixed z-40 inset-0 overflow-y-auto'
          onClose={() => null}
        >
          <div className='flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0'>
            <TransitionChild
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0'
              enterTo='opacity-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100'
              leaveTo='opacity-0'
            >
              <DialogBackdrop className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity'><Notification /></DialogBackdrop>
            </TransitionChild>

            <span
              className='hidden sm:inline-block sm:align-middle sm:h-screen'
              aria-hidden='true'
            >
              &#8203;
            </span>
            <TransitionChild
              as={Fragment}
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <div className='inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6'>
                <div>
                  <div>
                    <h3 className='text-lg font-medium leading-6 text-gray-900'>
                      Edit Comment
                    </h3>
                  </div>
                  <div>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className='mt-4'>
                        <div>
                          <label
                            htmlFor='comment'
                            className='block text-sm font-medium text-gray-700'
                          >
                            Comment
                          </label>
                          <div className='mt-1'>
                            <textarea
                              rows={4}
                              name='comment'
                              id='comment'
                              className='shadow-sm px-4 py-2 border focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md'
                              value={state.comment}
                              onChange={handleCommentChange}
                              placeholder='Edit comment'
                            />
                          </div>
                        </div>
                      </div>

                      <div className='mt-5 sm:mt-4 sm:flex sm:justify-end'>
                        <button
                          type='button'
                          className='w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:w-32 sm:text-sm'
                          onClick={() => setOpen(false)}
                        >
                          Cancel
                        </button>
                        <button
                          type='button'
                          disabled={state.loading}
                          className={
                            'mt-3 sm:ml-4 w-full inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:mt-0 sm:w-32 sm:text-sm'
                          }
                          onClick={editComment}
                        >
                          <span className='w-full flex justify-end'>
                            {state.loading ? (
                              <Icon name='loading' className='mr-2' />
                            ) : null}
                          </span>
                          <span>Update</span>
                          <span className='w-full'></span>
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </TransitionChild>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};

export default EditCommentModal;
