import {
  Combobox,
  ComboboxInput,
  ComboboxButton,
  ComboboxOptions,
  ComboboxOption,
  Field,
  Label,
} from '@headlessui/react';
import {
  CheckIcon,
  ChevronDownIcon,
  XMarkIcon,
} from '@heroicons/react/20/solid';
import { useEffect, useRef, useState } from 'react';
import { colorsList } from '../constants/colors';

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ');
}

type Props = {
  heading?: string;
  items: { [key: string]: any }[];
  selected: { [key: string]: any };
  type: string;
  defaultValue?: string;
  placeholder?: string;
  onChange: (item: any) => void;
  disabled?: boolean;
  open?: boolean;
  onBlur?: () => void;
  name?: string;
  noRecordText?: string;
};

export default function SingleSelectDropdown(props: Props) {
  const [query, setQuery] = useState<string>('');
  const [selected, setSelected] = useState(props.selected || { name: '' });
  const comboBtn = useRef<HTMLButtonElement | null>(null);
  const uniqueId = useRef(
    `focus-remover-${Math.random().toString(36).substring(2, 11)}`,
  );
  const options = props.items?.filter(Boolean);
  let selectedColor = {
    name: '',
    value: '',
  };

  if (props.type === 'colors') {
    selectedColor = colorsList?.find(
      (color: any) => color.name === props.selected.name,
    );
  } else if (props.type === 'task-status') {
    selectedColor = colorsList?.find(
      (color: any) => color.name === props.selected.color,
    );
  }

  const handleInputClick = () => comboBtn.current?.click();

  useEffect(() => {
    setSelected(props.selected || { name: '' });
    setQuery(props.selected?.name || '');
  }, [props.selected]);

  const onChange = (item: any) => {
    // Handle null/undefined item
    if (!item) {
      setQuery('');
      return;
    }

    // If clicking the same item that's already selected, clear the selection
    // if (item.name === props.selected?.name) {
    //   setSelected({ name: '' });
    //   props.onChange({ name: '' });
    //   setQuery('');
    // } else {
    // Otherwise, select the new item
    setSelected(item);
    props.onChange(item);
    // }

    // Remove focus from the combobox input
    setTimeout(() => {
      document.getElementById(uniqueId.current)?.focus();
    }, 100);
  };

  const filteredoptions =
    query.trim() === ''
      ? options
      : options?.filter((option) => {
          return option.name.toLowerCase().includes(query.toLowerCase());
        }) || [];

  return (
    <>
      <Field>
        <Label className='block text-sm font-medium text-gray-700'>
          {props.heading}
        </Label>
        <div className='relative'>
          <Combobox
            immediate
            as='div'
            value={selected?.name || ''}
            onChange={(item) => {
              onChange(item);
            }}
            disabled={props.disabled}
          >
            <div className={`relative ${props.heading ? 'mt-1' : ''}`}>
              <ComboboxInput
                style={{
                  backgroundColor:
                    props.type === 'colors' || props.type === 'task-status'
                      ? selectedColor?.value
                      : 'white',
                  color:
                    (props.type === 'colors' &&
                      props.selected.name.includes('Dark')) ||
                    (props.type === 'task-status' &&
                      selectedColor?.name.includes('Dark'))
                      ? 'white'
                      : 'black',
                }}
                className={`combo w-full rounded-md border border-gray-300 bg-white pr-10 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 text-sm capitalize ${
                  props.type === 'showTask' ? 'py-2' : 'py-2'
                } ${props.type === 'sort' ? 'pl-8' : 'pl-3'} ${
                  props.disabled ? 'cursor-not-allowed' : ''
                } `}
                onChange={(event: any) => {
                  setQuery(event.target.value);
                }}
                name={props.name}
                onBlur={props.onBlur}
                displayValue={props.selected?.name || ''}
                placeholder={props.placeholder}
                autoComplete='off'
                onClick={handleInputClick}
                onFocus={(e: any) => {
                  setQuery('');
                  return e.target.select();
                }}
              />
              <ComboboxButton
                ref={comboBtn}
                className='absolute top-2.5 right-0 flex items-center rounded-r-md px-2 focus:outline-none'
              >
                {({ open }) =>
                  open ? (
                    <XMarkIcon
                      className={`h-5 w-5 ${
                        (props.type === 'colors' &&
                          props.selected.name.includes('Light')) ||
                        (props.type === 'task-status' &&
                          selectedColor?.name.includes('Light'))
                          ? 'text-gray-500'
                          : 'text-gray-400'
                      } ${props.type !== 'colors' ? 'text-gray-400' : ''}`}
                      aria-hidden='true'
                    />
                  ) : (
                    <ChevronDownIcon
                      onClick={() => setQuery('')}
                      className={`h-5 w-5 ${
                        (props.type === 'colors' &&
                          props.selected.name.includes('Light')) ||
                        (props.type === 'task-status' &&
                          selectedColor?.name.includes('Light'))
                          ? 'text-gray-500'
                          : 'text-gray-400'
                      } ${props.type !== 'colors' ? 'text-gray-400' : ''}`}
                      aria-hidden='true'
                    />
                  )
                }
              </ComboboxButton>

              {filteredoptions?.length === 0 ? (
                <ComboboxOptions
                  className={`max-h-40 absolute z-50 mt-1 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm`}
                >
                  <ComboboxOption
                    disabled={true}
                    value={''}
                    className='relative whitespace-nowrap cursor-default select-none py-2 pl-3 pr-9 text-sm text-gray-900 sm:font-bold'
                  >
                    {props.noRecordText || 'No Record Available'}
                  </ComboboxOption>
                </ComboboxOptions>
              ) : (
                <ComboboxOptions
                  className={`${props.type === 'firms' ? 'max-h-120' : 'max-h-40'} absolute z-50 mt-1 w-full overflow-auto rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none text-sm vertical-scroll`}
                >
                  {filteredoptions?.map((option) => (
                    <ComboboxOption
                      key={`${option._id}`}
                      value={option}
                      className={({ focus }) =>
                        classNames(
                          'relative cursor-default select-none py-2 pl-3 pr-9 w-full',
                          focus ? 'bg-indigo-600 text-white' : 'text-gray-900',
                        )
                      }
                    >
                      {({ focus, selected }) => (
                        <>
                          <div className='flex items-center'>
                            {props.type === 'colors' && (
                              <span
                                style={{ background: option.value }}
                                className={
                                  'inline-block h-4 w-4 mr-3 bg-indigo-600 flex-shrink-0 rounded-full border border-slate-300'
                                }
                                aria-hidden='true'
                              />
                            )}
                            <span
                              className={classNames(
                                `block truncate ${
                                  props.type === 'period' ||
                                  props.type === 'bulkUpdateField'
                                    ? ''
                                    : 'capitalize'
                                }`,
                                selected && 'font-semibold',
                              )}
                            >
                              {option.name}
                              {option.name === props.selected.name ? (
                                <span
                                  className={classNames(
                                    'absolute inset-y-0 right-0 flex items-center pr-4',
                                    focus ? 'text-white' : 'text-indigo-600',
                                  )}
                                >
                                  <CheckIcon
                                    className='h-5 w-5'
                                    aria-hidden='true'
                                  />
                                </span>
                              ) : (
                                ''
                              )}
                            </span>
                          </div>

                          {selected && (
                            <span
                              className={classNames(
                                'absolute inset-y-0 right-0 flex items-center pr-4',
                                focus ? 'text-white' : 'text-indigo-600',
                              )}
                            >
                              <CheckIcon
                                className='h-5 w-5'
                                aria-hidden='true'
                              />
                            </span>
                          )}
                        </>
                      )}
                    </ComboboxOption>
                  ))}
                </ComboboxOptions>
              )}
            </div>
          </Combobox>
        </div>
      </Field>
      <input
        type='text'
        className='sr-only'
        id={uniqueId.current}
        tabIndex={-1}
      />
    </>
  );
}
