import { createContext, useContext, ReactNode, useState } from 'react';

interface FormProtectionContextType {
  setHasUnsavedChanges: (value: boolean) => void;
  hasUnsavedChanges: boolean;
}

const FormProtectionContext = createContext<FormProtectionContextType>({
  setHasUnsavedChanges: () => {},
  hasUnsavedChanges: false,
});

interface FormProtectionProviderProps {
  children: ReactNode;
}

export const FormProtectionProvider = ({
  children,
}: FormProtectionProviderProps) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);

  return (
    <FormProtectionContext.Provider
      value={{ hasUnsavedChanges, setHasUnsavedChanges }}
    >
      {children}
    </FormProtectionContext.Provider>
  );
};

export const useFormProtection = () => useContext(FormProtectionContext);
