import { createRef, useEffect, useState } from 'react';
import { ArrowContainer, Popover } from 'react-tiny-popover';

interface Props {
  content: any;
  children: any;
  className?: string;
  capitalize?: boolean;
}

interface IState {
  isPopoverOpen: boolean;
  popupArrowColor: string;
}

const initialState: IState = {
  isPopoverOpen: false,
  popupArrowColor: '',
};

export default function Popup(props: Props) {
  const popupRef = createRef<HTMLDivElement>();
  const [state, setState] = useState<IState>(initialState);

  useEffect(() => {
    // Add a small delay to ensure element is mounted and styled
    const timer = setTimeout(() => {
      if (popupRef.current instanceof Element) {
        setState((prevState) => ({
          ...prevState,
          popupArrowColor: window.getComputedStyle(popupRef.current)
            .backgroundColor,
        }));
      }
    }, 0);

    return () => clearTimeout(timer);
  }, [state.isPopoverOpen]);

  return !props.content ||
    props.content === '' ||
    props.content === ' ' ||
    props.content === '-' ? (
    <div className={props.className}>{props.children}</div>
  ) : (
    <Popover
      isOpen={state.isPopoverOpen}
      positions={['top', 'bottom', 'left', 'right']} // preferred positions by priority
      padding={4}
      containerStyle={{ zIndex: '9999' }}
      content={({ position, childRect, popoverRect }) => (
        <ArrowContainer // for showing the arrow below the popover
          position={position}
          childRect={childRect}
          popoverRect={popoverRect}
          arrowColor={state.popupArrowColor}
          arrowSize={7}
          className='popover-arrow-container'
          arrowClassName='popover-arrow'
        >
          <div
            ref={popupRef}
            className={`bg-stone-600 text-white rounded-md px-2 py-1 text-sm whitespace-pre-line max-w-xs break-words ${
              props.capitalize && 'capitalize'
            }`}
          >
            {props.content}
          </div>
        </ArrowContainer>
      )}
    >
      <div
        className={props.className}
        onMouseOver={() =>
          setState((prevState: any) => ({
            ...prevState,
            isPopoverOpen: true,
          }))
        }
        onMouseLeave={() =>
          setState((prevState: any) => ({
            ...prevState,
            isPopoverOpen: false,
          }))
        }
      >
        {props.children}
      </div>
    </Popover>
  );
}
