import { lazy, Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  BrowserRouter as Router,
  Navigate,
  Route,
  Routes,
} from 'react-router-dom';
import agent from './agent';
import Dashboard from './components/Dashboard';
import Icon from './components/Icon';
import Notification from './components/Notification';
import { Workspace } from './helpers/types';
import {
  onTokenPresent,
  onNotify,
  updateCommon,
  logout,
} from './store/reducers';
import { RootState } from './store/store';

import { FormProtectionProvider } from './context/FormProtectionContext';
import { RouteProtection } from './components/RouteProtection';
import TestPage from './pages/Verify/test';

const AdvanceFeesTrackingReport = lazy(
  () => import('./pages/Reports/FeesTracking/AdvanceFeesTrackingReport.tsx'),
);
const NonBilledReport = lazy(
  () => import('./pages/Reports/FeesTracking/NonBilledReport.tsx'),
);
const TasksNotCreated = lazy(
  () => import('./pages/Reports/TasksNotCreated.tsx'),
);
const BulkMessages = lazy(() => import('./pages/BulkMessages/Index'));
const TemplateList = lazy(() => import('./pages/BulkMessages/TemplateList'));
const AddClient = lazy(() => import('./pages/Clients/Add'));
const ClientDetails = lazy(() => import('./pages/Clients/ClientDetails'));
const ClientsImport = lazy(() => import('./pages/Clients/Import/Index'));
const ClientImportByGSTIN = lazy(() => import('./pages/Clients/ImportByGstin'));
const Clients = lazy(() => import('./pages/Clients/Index'));
const QRMP = lazy(() => import('./pages/Clients/QRMP/Index'));
const DSCStatus = lazy(() => import('./pages/ContactPerson/DSC'));
const ContactPersonImport = lazy(
  () => import('./pages/ContactPerson/Import/Index'),
);
const ContactPerson = lazy(() => import('./pages/ContactPerson/Index'));
const DashboardPage = lazy(() => import('./pages/Dashboard'));
const Firms = lazy(() => import('./pages/Firms/Index'));
const ForgetPassword = lazy(() => import('./pages/ForgetPassword'));
const HomePage = lazy(() => import('./pages/HomePage'));
const Login = lazy(() => import('./pages/Login'));
const NotFound = lazy(() => import('./pages/NotFound'));
const NotificationList = lazy(() => import('./pages/NotificationList/Index'));
const Category = lazy(() => import('./pages/ReceiptsPayments/Category'));
const ClientWiseReport = lazy(
  () => import('./pages/ReceiptsPayments/ClientWiseReport'),
);
const ReceiptsPayments = lazy(() => import('./pages/ReceiptsPayments/Index'));
const PendingBalanceReport = lazy(
  () => import('./pages/ReceiptsPayments/PendingBalanceReport'),
);
const Source = lazy(() => import('./pages/ReceiptsPayments/Source'));
const RegisterInOut = lazy(() => import('./pages/RegisterInOut'));
const Document = lazy(() => import('./pages/RegisterInOut/Document'));
const KeptAt = lazy(() => import('./pages/RegisterInOut/KeptAt'));
const Reports = lazy(() => import('./pages/Reports/Index'));
const ReportListing = lazy(() => import('./pages/Reports/ReportListing'));
const CustomFields = lazy(() => import('./pages/Settings/CustomField/Index'));
const GroupDetails = lazy(() => import('./pages/Settings/Groups/GroupDetails'));
const ClientGroups = lazy(() => import('./pages/Settings/Groups/Index'));
const RecurringTask = lazy(
  () => import('./pages/Settings/RecurringTask/Index'),
);
const Status = lazy(() => import('./pages/Settings/Status/Index'));
const Tags = lazy(() => import('./pages/Settings/Tag/Index'));
const Signup = lazy(() => import('./pages/Signup'));
const FeesTracking = lazy(
  () => import('./pages/Settings/FeesTracking/Index.tsx'),
);
const NotificationSettings = lazy(
  () => import('./pages/Settings/Notifications/Index.tsx'),
);
const Subscription = lazy(() => import('./pages/Subscription'));
const Invoices = lazy(() => import('./pages/Subscription/Invoices'));
const PaymentResponse = lazy(
  () => import('./pages/Subscription/paymentresponse'),
);
const AddTask = lazy(() => import('./pages/Tasks/Add'));
const TaskDetailModal = lazy(() => import('./pages/Tasks/Details'));
const Tasks = lazy(() => import('./pages/Tasks/Index'));
const ReturnTaskOverview = lazy(
  () => import('./pages/Tasks/ReturnTaskOverview/Index'),
);
const Todos = lazy(() => import('./pages/Todo/Index'));
const UpdateLogs = lazy(() => import('./pages/UpdateLogs/Index'));
const AddUser = lazy(() => import('./pages/Settings/Users/Add'));
const EditUser = lazy(() => import('./pages/Settings/Users/Add'));
const Users = lazy(() => import('./pages/Settings/Users/Index'));
const SelfRights = lazy(() => import('./pages/Settings/Users/SelfRIghts'));
const ResetOTP = lazy(() => import('./pages/Verify/ResetOTP'));
const ResetToken = lazy(() => import('./pages/Verify/ResetToken'));
const SignupOTP = lazy(() => import('./pages/Verify/SignupOTP'));
const SignupToken = lazy(() => import('./pages/Verify/SignupToken'));
const Profile = lazy(() => import('./pages/Profile/Index'));
const AttachmentsPage = lazy(() => import('./pages/Tasks/AttachmentsPage'));
const ClientAttachmentsPage = lazy(
  () => import('./pages/Clients/AttachmentsPage'),
);
const PaymentStatusPage = lazy(
  () => import('./pages/Subscription/PaymentStatus'),
);
const BulkEdit = lazy(() => import('./pages/BulkEdit'));

const SignupCompleted = lazy(() => import('./pages/Verify/SignUpCompleted'));
const Support = lazy(() => import('./pages/Support/Index'));
const TicketDetails = lazy(() => import('./pages/Support/ticketDetails'));

export async function logoutApiCall() {
  await agent.Auth.logout();
}

const App = () => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const authState = useSelector((state: RootState) => state.user);
  const urlParams = new URLSearchParams(window.location.search);

  const PrivateRoute = ({ children }: { children: JSX.Element }) => {
    if (loading) return <></>;
    const redirectPath = window.location.pathname;

    return authState.isAuthenticated ? (
      children
    ) : (
      <Navigate to={`/signin?r=${redirectPath}`} />
    );
  };

  const PublicRoute = ({ children }: { children: JSX.Element }) => {
    if (loading) return <></>;

    const redirectPath = urlParams.get('r');

    return !authState.isAuthenticated ? (
      children
    ) : (
      <Navigate to={redirectPath || '/dashboard'} />
    );
  };

  const getAllFirms = () => {
    setLoading(true);

    agent.Firm.getFirms()
      .then((response: { workspaces: Workspace[] }) => {
        const activeFirms = response.workspaces.filter((firm) => firm.active);
        dispatch(
          updateCommon({
            firms: activeFirms,
            isFirmPresent: activeFirms.length > 0,
          }),
        );

        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        dispatch(
          onNotify({
            title: 'Could not fetch Firms',
            message:
              err?.response?.data?.message ||
              err?.response?.data?.error ||
              err?.message ||
              err,
            type: 'danger',
          }),
        );
      });
  };

  useEffect(() => {
    checkToken();
  }, []);

  async function checkToken() {
    setLoading(true);
    try {
      const response = await agent.Auth.checkToken();

      if (response.success) {
        dispatch(onTokenPresent({ token: 'PRESENT' }));
      }
    } catch (e: any) {
      logoutApiCall();
      dispatch(logout());
    } finally {
      setLoading(false);
    }
  }

  useEffect(() => {
    if (authState.isAuthenticated) {
      getAllFirms();
    }
  }, [authState.isAuthenticated]);

  return (
    <div>
      <Notification />
      <Router>
        <FormProtectionProvider>
          <RouteProtection />
          <Dashboard>
            <Suspense fallback={<Icon name='loading-big' />}>
              <Routes>
                {/* home page - where we check logic and redirect accordingly */}
                <Route
                  path='/'
                  element={
                    <PrivateRoute>
                      <HomePage />
                    </PrivateRoute>
                  }
                />

                {/* Login */}
                <Route
                  path='/signin'
                  element={
                    <PublicRoute>
                      <Login />
                    </PublicRoute>
                  }
                />

                {/* SignUp */}
                <Route
                  path='/signup'
                  element={
                    <PublicRoute>
                      <Signup />
                    </PublicRoute>
                  }
                />

                <Route
                  path='/verify/signup/otp/:id'
                  element={
                    <PublicRoute>
                      <SignupOTP />
                    </PublicRoute>
                  }
                />
                <Route
                  path='/verify/signup/token/:token'
                  element={
                    <PublicRoute>
                      <SignupToken />
                    </PublicRoute>
                  }
                />

                <Route
                  path='/sign-up-completed'
                  element={
                    <PrivateRoute>
                      <SignupCompleted />
                    </PrivateRoute>
                  }
                />

                {/* PM2 Timeout Test */}
                <Route
                  path='/test'
                  element={
                    <PublicRoute>
                      <TestPage />
                    </PublicRoute>
                  }
                />

                {/* Forgot Password */}
                <Route
                  path='/forgetPassword'
                  element={
                    <PublicRoute>
                      <ForgetPassword />
                    </PublicRoute>
                  }
                />
                <Route
                  path='/verify/reset/otp/:id'
                  element={
                    <PublicRoute>
                      <ResetOTP />
                    </PublicRoute>
                  }
                />
                <Route
                  path='/verify/reset/token/:token'
                  element={
                    <PublicRoute>
                      <ResetToken />
                    </PublicRoute>
                  }
                />

                {/* Subscription Page */}
                <Route
                  path='/:firmId/subscriptions'
                  element={
                    <PrivateRoute>
                      <Subscription />
                    </PrivateRoute>
                  }
                />

                <Route
                  path='/:firmId/payment-status'
                  element={
                    <PrivateRoute>
                      <PaymentStatusPage />
                    </PrivateRoute>
                  }
                />

                <Route
                  path='/paymentresponse'
                  element={
                    <PrivateRoute>
                      <PaymentResponse />
                    </PrivateRoute>
                  }
                />

                {/* Invoices Page */}
                <Route
                  path='/:firmId/invoices'
                  element={
                    <PrivateRoute>
                      <Invoices />
                    </PrivateRoute>
                  }
                />

                {/* Dashboard */}
                <Route
                  path='/firms'
                  element={
                    <PrivateRoute>
                      <Firms />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/dashboard'
                  element={
                    <PrivateRoute>
                      <DashboardPage />
                    </PrivateRoute>
                  }
                />
                {/* Dashboard */}
                <Route
                  path='/:firmId/dashboard'
                  element={
                    <PrivateRoute>
                      <DashboardPage />
                    </PrivateRoute>
                  }
                />

                {/* Profile Page */}
                <Route
                  path='/:firmId/profile/'
                  element={
                    <PrivateRoute>
                      <Profile />
                    </PrivateRoute>
                  }
                />

                {/* Tag Page */}
                <Route
                  path='/:firmId/tags/list'
                  element={
                    <PrivateRoute>
                      <Tags />
                    </PrivateRoute>
                  }
                />
                {/* Status Page */}
                <Route
                  path='/:firmId/status/list'
                  element={
                    <PrivateRoute>
                      <Status />
                    </PrivateRoute>
                  }
                />
                {/* Custom Field Page */}
                <Route
                  path='/:firmId/custom-field/list'
                  element={
                    <PrivateRoute>
                      <CustomFields />
                    </PrivateRoute>
                  }
                />
                {/* Contact Perosn Page */}
                <Route
                  path='/:firmId/contact-person/list'
                  element={
                    <PrivateRoute>
                      <ContactPerson />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/contact-person/dsc-status'
                  element={
                    <PrivateRoute>
                      <DSCStatus />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/contact-person/import'
                  element={
                    <PrivateRoute>
                      <ContactPersonImport />
                    </PrivateRoute>
                  }
                />
                {/* Tasks  */}
                <Route
                  path='/:firmId/tasks/list'
                  element={
                    <PrivateRoute>
                      <Tasks />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/tasks/add'
                  element={
                    <PrivateRoute>
                      <AddTask />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/tasks/return-task-overview'
                  element={
                    <PrivateRoute>
                      <ReturnTaskOverview />
                    </PrivateRoute>
                  }
                />

                <Route
                  path='/:firmId/attachment/list'
                  element={
                    <PrivateRoute>
                      <AttachmentsPage />
                    </PrivateRoute>
                  }
                />

                {/* Todo Page */}
                <Route
                  path='/:firmId/todo/list/:list'
                  element={
                    <PrivateRoute>
                      <Todos />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/todo/:toDoListId'
                  element={
                    <PrivateRoute>
                      <Todos />
                    </PrivateRoute>
                  }
                />
                {/* User Page */}
                <Route
                  path='/:firmId/user/list'
                  element={
                    <PrivateRoute>
                      <Users />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/user/add'
                  element={
                    <PrivateRoute>
                      <AddUser />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/user/edit'
                  element={
                    <PrivateRoute>
                      <EditUser />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/user/self-rights'
                  element={
                    <PrivateRoute>
                      <SelfRights />
                    </PrivateRoute>
                  }
                />
                {/* Fees Tracking*/}
                <Route
                  path='/:firmId/fees-tracking/list'
                  element={
                    <PrivateRoute>
                      <FeesTracking />
                    </PrivateRoute>
                  }
                />
                {/* Fees Tracking*/}
                <Route
                  path='/:firmId/notifications-settings'
                  element={
                    <PrivateRoute>
                      <NotificationSettings />
                    </PrivateRoute>
                  }
                />
                {/* Receipts and payments */}
                <Route
                  path='/:firmId/receipts-payments/list'
                  element={
                    <PrivateRoute>
                      <ReceiptsPayments />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/receipts-payments/client-wise-reports'
                  element={
                    <PrivateRoute>
                      <ClientWiseReport />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/receipts-payments/pending-balance-reports'
                  element={
                    <PrivateRoute>
                      <PendingBalanceReport />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/receipts-payments/source'
                  element={
                    <PrivateRoute>
                      <Source />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/receipts-payments/category'
                  element={
                    <PrivateRoute>
                      <Category />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/register-in-out/list'
                  element={
                    <PrivateRoute>
                      <RegisterInOut />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/register-in-out/document'
                  element={
                    <PrivateRoute>
                      <Document />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/register-in-out/kept-at'
                  element={
                    <PrivateRoute>
                      <KeptAt />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/bulk-messages'
                  element={
                    <PrivateRoute>
                      <BulkMessages />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/bulk-message-template/list'
                  element={
                    <PrivateRoute>
                      <TemplateList />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/reports/list'
                  element={
                    <PrivateRoute>
                      <Reports />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/reports/:reportType/:report'
                  element={
                    <PrivateRoute>
                      <ReportListing />
                    </PrivateRoute>
                  }
                />

                <Route
                  path='/:firmId/reports/fees-tracking/non-billed-report'
                  element={
                    <PrivateRoute>
                      <NonBilledReport />
                    </PrivateRoute>
                  }
                />

                <Route
                  path='/:firmId/reports/fees-tracking/advance-report'
                  element={
                    <PrivateRoute>
                      <AdvanceFeesTrackingReport />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/reports/tasks-not-created'
                  element={
                    <PrivateRoute>
                      <TasksNotCreated />
                    </PrivateRoute>
                  }
                />

                <Route
                  path='/:firmId/:update/bulk-edit'
                  element={
                    <PrivateRoute>
                      <BulkEdit />
                    </PrivateRoute>
                  }
                />

                <Route
                  path='/:firmId/notification/list'
                  element={
                    <PrivateRoute>
                      <NotificationList />
                    </PrivateRoute>
                  }
                />

                {/* Clients */}
                <Route
                  path='/:firmId/clients/list'
                  element={
                    <PrivateRoute>
                      <Clients />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/clients/import'
                  element={
                    <PrivateRoute>
                      <ClientsImport />
                    </PrivateRoute>
                  }
                />

                <Route
                  path='/:firmId/clients/import-by-gstin'
                  element={
                    <PrivateRoute>
                      <ClientImportByGSTIN />
                    </PrivateRoute>
                  }
                />

                <Route
                  path='/:firmId/clients/qrmp'
                  element={
                    <PrivateRoute>
                      <QRMP />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/clients/add'
                  element={
                    <PrivateRoute>
                      <AddClient />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/clients/edit/:clientId'
                  element={
                    <PrivateRoute>
                      <AddClient />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/clients/attachment/list'
                  element={
                    <PrivateRoute>
                      <ClientAttachmentsPage />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/client-profile/:clientId'
                  element={
                    <PrivateRoute>
                      <ClientDetails />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/task/:taskId'
                  element={
                    <PrivateRoute>
                      <TaskDetailModal />
                    </PrivateRoute>
                  }
                />
                {/* Group */}
                <Route
                  path='/:firmId/groups/list'
                  element={
                    <PrivateRoute>
                      <ClientGroups />
                    </PrivateRoute>
                  }
                />
                <Route
                  path='/:firmId/group-profile/:groupId'
                  element={
                    <PrivateRoute>
                      <GroupDetails />
                    </PrivateRoute>
                  }
                />

                {/* Recuring Task */}
                <Route
                  path='/:firmId/recurring-task/list/:taskType'
                  element={
                    <PrivateRoute>
                      <RecurringTask />
                    </PrivateRoute>
                  }
                />

                {/* Update Logs */}
                <Route
                  path='/update-logs'
                  element={
                    <PrivateRoute>
                      <UpdateLogs />
                    </PrivateRoute>
                  }
                />

                {/* Support */}
                <Route
                  path='/:firmId/support'
                  element={
                    <PrivateRoute>
                      <Support />
                    </PrivateRoute>
                  }
                />

                <Route
                  path='/:firmId/ticket-details/:ticketId'
                  element={
                    <PrivateRoute>
                      <TicketDetails />
                    </PrivateRoute>
                  }
                />

                {/* No Match */}
                <Route
                  path='*'
                  element={<NotFound loggedIn={authState.isAuthenticated} />}
                />
              </Routes>
            </Suspense>
          </Dashboard>
        </FormProtectionProvider>
      </Router>
    </div>
  );
};

export default App;
